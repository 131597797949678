import BaseStore from './BaseStore';

export type TBug = {
  bug_id: number;
  project_id: number;
  project_name?: string;
  bug_url: string;
  bug_text: string;
  user_id: number;
  user_name: string;
  bug_device: string;
  bug_status?: string;
  bug_time: moment.Moment | string;
  bug_images?: string[];
  img_id?: number;
  img_mime?: string;
};
class BugsStore extends BaseStore {
  idKey = 'bug_id';
  get data(): TBug[] {
    return this._data as TBug[];
  }
}
export default BugsStore;
