import ImageList from 'components/Heplers/ImageList';
import TextStatusShow from 'components/Heplers/TextStatusShow';
import UserShow from 'components/Heplers/UserShow';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Badge, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { TaskFlow } from 'stores/TasksStore';
import { TBug } from 'stores/BugsStore';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useStores } from '../../services/useStore';

export interface IBugInfo {
  bugId: number | undefined;
  linkInDialog?: boolean;
}

const BugInfo: React.FC<IBugInfo> = observer(({ bugId, linkInDialog }) => {
  const { t } = useTranslation();
  const { api } = useStores();
  const [bug, setBug] = useState<TBug>();
  const [inited, setInit] = useState(false);
  const getUrl = (img: number) => `${api.settings.url.server}bug/${img}.png`;
  const date = (dateValue: moment.Moment | string) =>
    dateValue ? moment(dateValue).format('DD.MM.YY HH:mm') : '';
  const showDevice = (deviceText: string): JSX.Element[] => {
    try {
      const data = JSON.parse(deviceText);
      const list: JSX.Element[] = [];
      Object.entries(data).forEach(([key, value]) => {
        list.push(
          <div key={key} style={{ float: 'left', paddingRight: 10 }}>
            <b>{key}</b>: {value as string},
          </div>
        );
      });
      console.log(list);
      return list;
    } catch (e) {
      console.log(e);
      return [<></>];
    }
  };
  useEffect(() => {
    setInit(false);
    if (bugId) {
      api.getBugs(+bugId).then((data) => {
        const lo = data as TBug;
        console.log(lo);
        setBug(lo);
        setInit(true);
      });
    }
  }, [bugId]);
  if (!inited) return <div>{t('global.loading')}</div>;
  //if (!api.isAdmin()) return <div>Access deny</div>;
  return (
    <Container fluid className='verticalPadding greenForm '>
      <Row className='justify-content-md-center'>
        <Col className='mb-3'>
          <div style={{ marginTop: 20 }}>
            {date(bug.bug_time)} <Badge>{bug.bug_status}</Badge>{' '}
            {bug.bug_url === '/' ? '' : <Link to={bug.bug_url}>{bug.bug_url}</Link>}
          </div>
          <a href={getUrl(bug.bug_id)} rel='noreferrer' target='_blank'>
            <img className='admin-middle-pic' src={getUrl(bug.bug_id)} alt='' />
          </a>
          <p>{bug.bug_text}</p>
          <div style={{ fontSize: '0.7rem' }}>
            {showDevice(bug.bug_device.replaceAll("'", '"'))}
          </div>
          <div style={{ clear: 'both' }}>
            <br />
            <UserShow userId={bug.user_id} isManager />
          </div>
        </Col>
      </Row>
    </Container>
  );
});
export default BugInfo;
