/* eslint-disable no-nested-ternary */
import { observer } from 'mobx-react';
import React, { CSSProperties, useEffect, useState } from 'react';
import {
  ExclamationTriangle,
  FileEarmarkText,
  Gift,
  InfoCircle,
  Pencil,
  Plus,
  Trash,
  Tree,
} from 'react-bootstrap-icons';
import { Badge, Button, Col, Container, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'components/App/App';
import moment from 'moment';
import BootstrapTable from 'react-bootstrap-table-next';
import { type } from 'os';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { Link } from 'react-router-dom';
import UserShow from 'components/Heplers/UserShow';
import { TBug } from 'stores/BugsStore';
import { useStores } from '../../services/useStore';
import Home from './Home';

const BugListAdmin: React.FC = observer(() => {
  const { t } = useTranslation();
  const { bugsStore, api } = useStores();
  const getUrl = (img: string) => `url('${api.settings.url.server}bug/${img}')`;
  if (!api.isAdmin()) return <Home />;
  const [inited, setInit] = useState(false);
  let { data } = bugsStore;
  if (!data) data = [];
  useEffect(() => {
    if (!bugsStore?.data?.length) {
      api.getBugs().then((list: TBug | TBug[]) => {
        console.log(list);
        setInit(true);
      });
    } else {
      setInit(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const info = (projectId: number) => {};

  if (!inited) {
    return (
      <div style={{ display: 'flex', height: '100%' }}>
        <div style={{ margin: 'auto', display: 'flex' }}>
          <Spinner animation='border' role='status' />
          <span className='spiner-text'>{t('loading')}</span>
        </div>
      </div>
    );
  }

  const deleteFormatter = (cell: undefined, row: TBug) => {
    return (
      <>
        <button
          type='button'
          onClick={() => api.showDialog('bug', row.bug_id)}
          className='admin-small-button btn btn-info btn-sm'
        >
          <InfoCircle />
        </button>{' '}
        <button
          type='button'
          className='admin-small-button btn btn-danger btn-sm'
          onClick={() => {
            api.deleteItem('bugs', row.bug_id, t('global.deletePrompt')).then((result) => {
              bugsStore.remove(row.bug_id);
              window.location.reload();
            });
          }}
        >
          <Trash />
        </button>
      </>
    );
  };
  const date = (dateValue: moment.Moment | string) =>
    dateValue ? moment(dateValue).format('DD.MM.YY HH:mm') : '';
  const tdStyle: CSSProperties = {
    padding: 5,
  };
  const columns = [
    {
      dataField: 'bug_id',
      text: '#',
      sort: true,
      align: 'center',
      headerStyle: { width: 40 },
      style: tdStyle,
    },
    {
      dataField: 'image',
      text: '',
      sort: false,
      headerStyle: { width: 70 },
      formatter: (cell: undefined, row: TBug) => (
        <div className='admin-small-pic' style={{ backgroundImage: getUrl(row.bug_id + '.png') }} />
      ),
      style: {
        padding: '0 0 1px 0',
      },
    },
    {
      dataField: 'bug_url',
      text: t('bug.url'),
      formatter: (cell: undefined, row: TBug) =>
        row.bug_url === '/' ? '' : <Link to={row.bug_url}>{row.bug_url}</Link>,
      sort: true,
      headerStyle: { width: 100 },
    },
    {
      dataField: 'bug_text',
      text: t('bug.text'),
      formatter: (cell: undefined, row: TBug) => row.bug_text.substring(0, 100),
      style: tdStyle,
    },
    {
      dataField: 'user_id',
      text: 'User',
      sort: true,
      headerStyle: { width: 200 },
      style: tdStyle,
      formatter: (cell: undefined, row: TBug) => (
        <UserShow
          userId={row.user_id}
          showAva={false}
          linkInDialog
          isManager
          allUsers
          hideEmpty
          showId
        />
      ),
    },
    {
      dataField: 'bug_time',
      text: t('bug.created'),
      sort: true,
      headerStyle: { width: 110 },
      formatter: (cell: undefined, row: TBug) => date(row.bug_time),
      style: tdStyle,
    },
    {
      dataField: 'project_status',
      text: t('bug.status'),
      sort: true,
      headerStyle: { width: 100 },
      align: 'center',
      formatter: (cell: undefined, row: TBug) => (
        <Badge style={{ fontSize: 10 }}>{row.bug_status}</Badge>
      ),
      style: tdStyle,
    },
    {
      dataField: 'edit',
      text: '',
      editable: false,
      csvExport: false,
      align: 'center',
      headerStyle: { width: 100 },
      formatter: deleteFormatter,
      style: tdStyle,
    },
  ];

  const rowStyle = (row: TBug, rowIndex: number) => {
    const style = {} as React.CSSProperties;
    style.fontSize = '0.7rem';
    return style;
  };

  return (
    <Container fluid className='verticalPadding greenForm'>
      <Row className='mb-3 mt-3'>
        <Col sm={6}>
          <h1>{t('bug.bugs')}</h1>
        </Col>
      </Row>
      <Row>
        <Col lg={12} style={{ background: '#fff', padding: 0 }}>
          <ToolkitProvider
            bootstrap4
            keyField='bug_id'
            data={bugsStore?.data}
            columns={columns}
            search
          >
            {(props) => (
              <Container fluid>
                <Row>
                  <Col lg={12} style={{ background: '#fff', padding: 0 }}>
                    <BootstrapTable {...props.baseProps} rowStyle={rowStyle} />
                  </Col>
                </Row>
              </Container>
            )}
          </ToolkitProvider>
        </Col>
      </Row>
    </Container>
  );
});
export default BugListAdmin;
