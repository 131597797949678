import { observer } from 'mobx-react';
import React from 'react';
import { Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import BugInfo from './BugInfo';

const BugsBotShow: React.FC = observer(() => {
  const bugId = useParams().params || 0;
  return (
    <Container style={{ marginTop: 14 }}>
      <BugInfo bugId={+bugId} />
    </Container>
  );
});
export default BugsBotShow;
