import React from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Person } from 'react-bootstrap-icons';
import { observer } from 'mobx-react';
import { useStores } from 'services/useStore';
import { ROUTES } from 'components/App/App';
import { useTranslation } from 'react-i18next';
import GoogleLogoutButton from '../Buttons/GoogleLogoutButton';

const ProfileBlock: React.FC = observer(() => {
  const { t } = useTranslation();
  const { sessionStore, api, requestStore } = useStores();
  const { session } = sessionStore;
  const { persons } = session;
  return (
    <Dropdown>
      <Dropdown.Toggle variant='secondary' id='dropdown-basic'>
        <Person />
        {'  '}
        <b>{session.user_name}</b>
      </Dropdown.Toggle>
      <Dropdown.Menu style={{ right: 0, left: 'auto', width: 400 }}>
        {/*sessionStore.projectName() ? (
          <Dropdown.ItemText>
            {t('header.project')}:&nbsp;
            <b>{sessionStore.projectName()}</b>
          </Dropdown.ItemText>
        ) : (
          ''
        )*/}
        {/*<Dropdown.ItemText>
          <b>{session.user_email}</b>
      </Dropdown.ItemText>*/}
        {/*<Dropdown.Item as={Link} to={ROUTES.payments}>
          {t('header.billing')}
        </Dropdown.Item>*/}
        <Dropdown.Item as={Link} to={ROUTES.profile}>
          {t('header.profile')}
        </Dropdown.Item>
        {requestStore?._data?.length || persons > 0 ? <Dropdown.Divider /> : ''}
        {requestStore?._data?.length ? (
          <Dropdown.Item as={Link} to={ROUTES.myRequests}>
            {t('header.myRequest')}
          </Dropdown.Item>
        ) : (
          ''
        )}
        {persons > 0 ? (
          <Dropdown.Item as={Link} to={ROUTES.myPersons}>
            {t('header.myPersons')}
          </Dropdown.Item>
        ) : (
          ''
        )}
        <Dropdown.Divider />
        {sessionStore.session.login_as === 'default' ||
        sessionStore.session.login_as === 'none' ||
        sessionStore.session.login_as === 'fb' ? (
          <Dropdown.Item as={Button} style={{ boxShadow: 'none' }} onClick={() => api.logout()}>
            {t('header.logout')}
          </Dropdown.Item>
        ) : (
          ''
        )}
        {sessionStore.session.login_as === 'google' ? (
          <Dropdown.Item>
            <GoogleLogoutButton clientId={api.settings.serverSettings.googleClientId} />
          </Dropdown.Item>
        ) : (
          ''
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
});

export default ProfileBlock;
