/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useEffect, useRef, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useStores } from 'services/useStore';
import { TNews } from 'stores/NewsStore';
import { TProject } from 'stores/ProjectsStore';
import { Person, Terminal } from 'react-bootstrap-icons';
import MarkdownText from './MarkdownText';

export type TNewsCard = {
  news: TNews;
};

const NewsCard: React.FC<TNewsCard> = ({ news }) => {
  const { api, projectStore } = useStores();
  const ref = useRef<HTMLDivElement>(null);
  const [projectName, setProjectName] = useState('');
  const [descripionHeight, setDescripionHeight] = useState('auto');
  const resize = () => {
    if (ref.current && ref.current.clientHeight > 300) {
      setDescripionHeight('250px');
    } else setDescripionHeight('auto');
  };
  const imageName = () => {
    if (news.img_id) return news.img_id + '.' + news.img_mime;
    if (news.project_id) {
      const projectData: TProject = projectStore.get(news.project_id);
      if (projectData.img_id) return projectData.img_id + '.' + projectData.img_mime;
    }
    return '0.png';
  };

  useEffect(() => {
    if (news.project_id) {
      if (news.project_name) setProjectName(news.project_name);
      else {
        const projectInfo: TProject = projectStore.get(news.project_id);
        if (projectInfo) setProjectName(projectInfo.project_name);
      }
    }
    setTimeout(resize, 100);
    const resizeObserver = new ResizeObserver(resize);
    if (ref.current) resizeObserver.observe(ref.current);
    return () => {
      if (ref.current) resizeObserver.unobserve(ref.current);
    };
  }, []);

  if (news.news_draft) return <></>;
  const currentId = news.news_id;

  const handler = (event: React.MouseEvent, clickId: number) => {
    event.preventDefault();
    event.stopPropagation();
    api.showDialog('news', currentId);
    return false;
  };

  return (
    <Card
      className={'news-card' + (!news.project_id ? ' LT-news' : '')}
      ref={ref}
      onClick={(event) => handler(event, currentId)}
    >
      <div
        className='image'
        style={{
          backgroundImage: `url(${api.settings.url.server}img/${imageName()})`,
        }}
      />
      <div className='news-data'>
        <div className='news-header'>
          <div className='name'>{news.project_id ? projectName : 'LARP tools'}</div>
          <div className='time'>{api.timeFormat(news.news_created)}</div>
          {news.user_id ? (
            <div role='link' style={{ display: 'flex', alignItems: 'center', fontSize: '0.8rem' }}>
              <Person />
              {'  '}
              <b>{news.user_name}</b>
            </div>
          ) : (
            ''
          )}
        </div>
        <div className='description' style={{ height: descripionHeight }}>
          <MarkdownText text={news.news_description} />
        </div>
        {descripionHeight !== 'auto' ? <div className='over' /> : ''}
      </div>
    </Card>
  );
};
export default NewsCard;
