import React, { useEffect, useState, useCallback } from 'react';
import { Alert, Button, Col, Container, FloatingLabel, Form, Row } from 'react-bootstrap';
import { useStores } from 'services/useStore';
import { useTranslation } from 'react-i18next';
import { TSuccessResponseWihNews } from 'services/Api';
import { observer } from 'mobx-react';
import { TPerson } from 'stores/PersonsStore';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from 'components/App/App';
import ImageList from 'components/Heplers/ImageList';
import LocationSelect from 'components/Locations/LocationSelect';
import UserSelect from 'components/Heplers/UserSelect';
import LinkList from 'components/Heplers/LinkList';
import PersonLinkList from 'components/Heplers/PersonLinkList';
import TextEditor from 'components/Heplers/TextEditor';
import UserShow from 'components/Heplers/UserShow';
import { TUser } from 'stores/UsersStore';
import AITextButton from 'components/Buttons/AITextButton';
import DraftCheckBox from 'components/Heplers/DraftCheckBox';
import InfoButton from 'components/Heplers/InfoButton';
import RequestByPersonList from 'components/Heplers/RequestByPersonList';
import CreateNewsCheckBox from 'components/Heplers/CreateNewsCheckBox';
import VirtualCheckBox from 'components/Heplers/VirtualCheckBox';
import RedirectCheck from 'components/Heplers/RedirectCheck';
//import styles from './style.module.scss';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let timer: any;

const PersonEdit: React.FC = observer(() => {
  const pId = useParams().params || 0;
  const history = useNavigate();
  const { t } = useTranslation();
  const { api, personStore, userStore } = useStores();
  api.noLogin();
  const [personId, setPersonId] = useState(+pId);
  const [validated, setValidated] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [name, setName] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [notice, setNotice] = useState('');
  const [status, setStatus] = useState('');
  const [draft, setDraft] = useState(1);
  const [virtual, setVirtual] = useState(0);
  const [createNews, setCreateNews] = useState(0);
  const [type, setType] = useState('prewrite');
  const [master, setMaster] = useState(0);
  const [user, setUser] = useState(0);
  const [location, setLocation] = useState(0);
  const [currentUser, setCurrentUser] = useState(0);
  const [successText, setSuccessText] = useState('');
  const [requestList, setRequestList] = useState<TUser[]>();
  const [imgList, setImgList] = useState([] as string[]);

  const onTitle = useCallback((value: string) => {
    setTitle(value);
  }, []);

  const onDescription = useCallback((value: string) => {
    setDescription(value);
  }, []);

  const onLocation = useCallback((value: number) => {
    setLocation(value);
  }, []);

  const onCreateNews = useCallback((value: number) => {
    setCreateNews(value);
  }, []);

  const onDraft = useCallback((value: number) => {
    setDraft(value);
  }, []);

  const updateData = () => {
    if (personId) {
      setLoaded(false);
      api.getPersons(personId).then((data) => {
        //const person = data as TPerson;
        const person = personStore.get(personId);
        setName(person.person_name);
        setTitle(person.person_title);
        setDescription(person.person_description || '');
        setNotice(person.person_notice || '');
        setStatus(person.person_status || '');
        setType(person.person_type || '');
        setMaster(person.person_master || 0);
        setUser(person.user_id || 0);
        setLocation(person.location_id || 0);
        setDraft(person.person_draft || 0);
        setVirtual(person.person_virtual || 0);
        setCurrentUser(person.user_id || 0);
        personStore.update(person);
        if (person.person_images) setImgList(person.person_images);
        else setImgList([]);
        setRequestList(userStore.getUsers().filter((u: TUser) => u.person_id === personId));
        setLoaded(true);
      });
      //} else setRequestList(userStore.users);
    } else setLoaded(true);
  };

  useEffect(() => {
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    setPersonId(+pId);
  }, [pId]);

  useEffect(() => {
    updateData();
  }, [personId]);

  const updateImages = useCallback(() => {
    api.getPersons(personId).then((data) => {
      const person = data as TPerson;
      if (person.person_images) setImgList(person.person_images);
      else setImgList([]);
    });
  }, []);

  const updatePerson = (event: React.FormEvent) => {
    const form = event.currentTarget as HTMLFormElement;
    if (form.checkValidity()) {
      setValidated(true);
      const data: TPerson = {
        person_name: name.trim(),
        person_title: title.trim(),
        person_description: description.trim(),
        person_notice: notice.trim(),
        person_status: status.trim(),
        person_type: type.trim(),
        person_master: master,
        person_draft: draft,
        person_virtual: virtual,
        user_id: virtual ? 0 : user,
        location_id: location,
        createNews,
      };
      if (personId) data.person_id = personId;
      api.setPerson(data).then((res: TSuccessResponseWihNews) => {
        const { errors, success } = res;
        if (!errors) {
          const newsId = success?.news_id || 0;
          api.removeCacheFor(api.settings.url.person);
          if (personId || success?.person_id) {
            let dataId = 0;
            if (personId) dataId = personId;
            else dataId = Number.isInteger(success?.person_id) && success ? success?.person_id : 0;
            setPersonId(dataId);
            personStore.update({ data, person_id: dataId });
            api.removeCacheFor(api.settings.url.person + '/' + dataId);
            api.getPersons(dataId).then((personData) => {
              const lo: TPerson = personData as TPerson;
              setMaster(lo.person_master || 0);
              if (!personId) personStore.add({ person_id: dataId, ...lo });
              else personStore.update(lo);
              if (newsId) {
                history(ROUTES.newsEdit + '/' + newsId);
              }
            });
          } else if (success) {
            //personStore.add({ person_id: +success, ...data });
            setPersonId(+success.person_id);
            if (newsId) {
              history(ROUTES.newsEdit + '/' + newsId);
            }
          }
          console.log('OK');
          setSuccessText(personId ? 'personForm.editSuccess' : 'personForm.createSuccess');
          timer = setTimeout(() => {
            setSuccessText('');
          }, 5000);
        }
      });
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      event.stopPropagation();
    }

    return false;
  };
  const successAlert = successText ? <Alert variant='success'>{t(successText)}</Alert> : '';
  if (!loaded) return <></>;
  return (
    <Container fluid className='verticalPadding greenForm'>
      <RedirectCheck url={ROUTES.locations} />
      <Form onSubmit={updatePerson} action='#' validated={validated}>
        <Row className='justify-content-md-center'>
          <Col xs lg={10}>
            <h2>
              {t(personId ? 'personForm.edit' : 'personForm.create')}
              {personId ? <span className='text-muted'> #{personId}</span> : ''}
            </h2>
            {virtual ? (
              ''
            ) : (
              <>
                <FloatingLabel controlId='type' label={t('personForm.type')} className='mb-3'>
                  <Form.Select
                    aria-label={t('personForm.type')}
                    required
                    size='lg'
                    value={type}
                    onChange={(e) => setType(e.currentTarget.value)}
                  >
                    <option value='free'>{t('personForm.types.free')}</option>
                    <option value='prewrite'>{t('personForm.types.prewrite')}</option>
                  </Form.Select>
                </FloatingLabel>
                <Alert variant='info'>{t('personForm.typesDescription.' + type)}</Alert>
              </>
            )}
            <FloatingLabel controlId='name' label={t('personForm.name')} className='mb-3'>
              <Form.Control
                type='text'
                required
                size='lg'
                value={name || ''}
                onChange={(e) => setName(e.target.value)}
                placeholder={t('personForm.name')}
              />
            </FloatingLabel>
            <VirtualCheckBox virtual={virtual} callback={setVirtual} />
            {!virtual ? (
              <>
                <UserSelect userId={user} userList={requestList} action={setUser} />
                {user ? (
                  <div className='mt-2'>
                    <UserShow userId={user} showAva linkInDialog />
                  </div>
                ) : (
                  ''
                )}
              </>
            ) : (
              ''
            )}

            <FloatingLabel
              controlId='title'
              label={title ? '' : t('personForm.title')}
              className='mt-3 mb-3'
            >
              <Form.Control
                as='textarea'
                rows={5}
                size='lg'
                value={title || ''}
                onChange={(e) => setTitle(e.target.value)}
                placeholder={t('personForm.title')}
                style={{ height: '100px' }}
              />
              <AITextButton handler={onTitle} text={title} />
            </FloatingLabel>
            <div style={{ opacity: 0.5, fontSize: 14 }}>{t('personForm.description_title')}</div>
            <FloatingLabel
              controlId='description'
              label={description ? '' : t('personForm.description')}
              className='mb-3 protectedField'
            >
              <Form.Control
                as='textarea'
                rows={5}
                size='lg'
                value={description || ''}
                onChange={(e) => setDescription(e.target.value)}
                placeholder={t('personForm.description')}
                style={{ height: '100px' }}
              />
              <AITextButton handler={onDescription} text={description} />
            </FloatingLabel>
            <TextEditor
              text={notice || ''}
              onChange={(value: string) => setNotice(value)}
              fromId={personId}
              type='person'
              linkStoreName='personLinks'
              placeholder={t('personForm.notice')}
              linksType={['stuff', 'person']}
              height={250}
            />
            <Row>
              <Col xs lg={11}>
                <FloatingLabel
                  controlId='status'
                  label={t('personForm.status')}
                  className='mb-3 masterField'
                >
                  <Form.Control
                    type='text'
                    size='lg'
                    value={status || ''}
                    onChange={(e) => setStatus(e.target.value)}
                    placeholder={t('personForm.status')}
                  />
                </FloatingLabel>
              </Col>
              <Col xs lg={1}>
                <InfoButton text='personStatus' />
              </Col>
            </Row>
            <LocationSelect locationId={location} action={onLocation} />
            <DraftCheckBox draft={draft} callback={onDraft} />
            <CreateNewsCheckBox draft={draft} createNews={createNews} callback={onCreateNews} />
            {successAlert}
          </Col>
        </Row>
        <Row className='justify-content-md-center'>
          <Col xs={2}>
            <div className='d-grid gap-2'>
              <Button variant='primary' className='col-12' size='lg' type='submit'>
                {t('global.save')}
              </Button>
            </div>
          </Col>
          <Col xs={2}>
            <div className='d-grid gap-2'>
              <Link to={ROUTES.persons}>
                <Button variant='secondary' className='col-12' size='lg'>
                  {t('global.back')}
                </Button>
              </Link>
            </div>
          </Col>
        </Row>
      </Form>
      {personId ? (
        <>
          <Row className='justify-content-md-center mb-3'>
            <Col xs lg={10}>
              <h2>{t('personForm.extendedHeader')}</h2>
              <UserSelect userId={master} action={setMaster} isManager />
            </Col>
          </Row>
          <Row className='justify-content-md-center mb-3'>
            <Col lg={3}>
              <LinkList
                type='person'
                subType='status'
                id={personId}
                storeName='personLinks'
                title={t('status.mainTitle')}
                linkInDialog
              />
            </Col>
            <Col lg={4}>
              <LinkList
                type='person'
                subType='stuff'
                id={personId}
                storeName='personLinks'
                title={t('stuff.mainTitle')}
                muteLoadFromAPI
                autogenerate
                linkInDialog
              />
            </Col>
            <Col lg={3}>
              <LinkList
                type='person'
                subType='quest'
                id={personId}
                storeName='personLinks'
                title={t('quest.mainTitle')}
                muteLoadFromAPI
                autogenerate
                linkInDialog
              />
            </Col>
          </Row>
          <Row className='justify-content-md-center mb-3'>
            <Col lg={virtual ? 10 : 5}>
              <PersonLinkList id={personId} muteLoadFromAPI autogenerate linkInDialog />
            </Col>
            {!virtual ? (
              <Col lg={5}>
                <RequestByPersonList currentUser={currentUser} personId={personId || 0} />
              </Col>
            ) : (
              ''
            )}
          </Row>
          <Row className='justify-content-md-center mb-3'>
            <Col lg={10}>
              <LinkList
                type='person'
                subType='text'
                id={personId}
                storeName='personLinks'
                title={t('text.mainTitle')}
                muteLoadFromAPI
                linkInDialog
              />
            </Col>
          </Row>
          <Row className='justify-content-md-center mb-3'>
            <Col lg={10}>
              <ImageList
                id={personId}
                type='person'
                genImgText={title}
                imgList={imgList}
                updateImagesHandle={updateImages}
                uploadUrl={'uploadPersonImage/' + personId}
              />
            </Col>
          </Row>
        </>
      ) : (
        ''
      )}
    </Container>
  );
});

export default PersonEdit;
