import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Button, Col, Container, FloatingLabel, Form, Row } from 'react-bootstrap';
import { useStores } from 'services/useStore';
import { useTranslation } from 'react-i18next';
import { TSuccessResponse } from 'services/Api';
import { observer } from 'mobx-react';
import { TTask } from 'stores/TasksStore';
import { Link, useParams } from 'react-router-dom';
import { ROUTES } from 'components/App/App';
import ImageList from 'components/Heplers/ImageList';
import UserSelect from 'components/Heplers/UserSelect';
import TextEditor from 'components/Heplers/TextEditor';
import RedirectCheck from 'components/Heplers/RedirectCheck';
//import styles from './style.module.scss';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let timer: any;

const TaskEdit: React.FC = observer(() => {
  const pId = useParams().params || 0;
  const { t } = useTranslation();
  const { api, taskStore } = useStores();
  api.noLogin();
  const [taskId, setTaskId] = useState(+pId);
  const [validated, setValidated] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [status, setStatus] = useState('');
  const [flow, setFlow] = useState('open');
  const [assign, setAssign] = useState(0);
  const [successText, setSuccessText] = useState('');
  const [imgList, setImgList] = useState([] as string[]);

  const onAssign = useCallback((value: number) => {
    setAssign(value);
  }, []);

  useEffect(() => {
    if (taskId) {
      api.getTasks(taskId).then((data) => {
        const task = data as TTask;
        setName(task.task_name);
        setDescription(task.task_description || '');
        setStatus(task.task_status || '');
        setFlow(task.task_flow || 'open');
        setAssign(task.task_assign || 0);
        taskStore.update(task);
        if (task.task_images) setImgList(task.task_images);
        else setImgList([]);
      });
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateTask = (event: React.FormEvent) => {
    const form = event.currentTarget as HTMLFormElement;
    if (form.checkValidity()) {
      setValidated(true);
      const data: TTask = {
        task_name: name.trim(),
        task_description: description.trim(),
        task_status: status.trim(),
        task_flow: flow,
        task_assign: assign,
      };
      if (taskId) data.task_id = taskId;
      api.setTask(data).then((res: TSuccessResponse) => {
        const { errors, success } = res;
        if (!errors) {
          api.removeCacheFor(api.settings.url.tasks);
          if (taskId || (Number.isInteger(success) && success)) {
            let dataId = 0;
            if (taskId) dataId = taskId;
            else dataId = Number.isInteger(success) && success ? +success : 0;
            setTaskId(dataId);
            api.removeCacheFor(api.settings.url.tasks + '/' + dataId);
            api.getTasks(dataId).then((taskData) => {
              const lo: TTask = taskData as TTask;
              setAssign(lo.task_assign || 0);
              if (!taskId) taskStore.add({ task_id: dataId, ...lo });
              else taskStore.update(lo);
            });
          } else if (success) {
            //taskStore.add({ task_id: +success, ...data });
            setTaskId(+success);
          }
          console.log('OK');
          setSuccessText(taskId ? 'taskForm.editSuccess' : 'taskForm.createSuccess');
          timer = setTimeout(() => {
            setSuccessText('');
          }, 5000);
        }
      });
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      event.stopPropagation();
    }

    return false;
  };
  const successAlert = successText ? <Alert variant='success'>{t(successText)}</Alert> : '';
  return (
    <Container fluid className='verticalPadding greenForm'>
      <RedirectCheck url={ROUTES.tasks} />
      <Form onSubmit={updateTask} action='#' validated={validated}>
        <Row className='justify-content-md-center'>
          <Col xs lg={10}>
            <h2>
              {t(taskId ? 'taskForm.edit' : 'taskForm.create')}
              {taskId ? <span className='text-muted'> #{taskId}</span> : ''}
            </h2>
            <FloatingLabel controlId='name' label={t('taskForm.name')} className='mb-3'>
              <Form.Control
                type='text'
                required
                size='lg'
                value={name || ''}
                onChange={(e) => setName(e.target.value)}
                placeholder={t('taskForm.name')}
              />
            </FloatingLabel>
            <TextEditor
              text={description || ''}
              onChange={(value: string) => setDescription(value)}
              fromId={taskId}
              type='person'
              linkStoreName='personLinks'
              placeholder={t('personForm.description')}
              linksType={['stuff', 'person']}
              height={250}
              createLinks={false}
            />
            <FloatingLabel controlId='status' label={t('taskForm.status')} className='mb-3'>
              <Form.Control
                type='text'
                size='lg'
                value={status || ''}
                onChange={(e) => setStatus(e.target.value)}
                placeholder={t('taskForm.status')}
              />
            </FloatingLabel>
            <FloatingLabel controlId='flow' label={t('taskForm.flow')} className='mb-3'>
              <Form.Select
                aria-label={t('taskForm.flow.name')}
                required
                size='lg'
                value={flow}
                onChange={(e) => setFlow(e.currentTarget.value)}
              >
                <option key='open' value='open'>
                  {t(`taskForm.flow.open`)}
                </option>
                <option key='inProgress' value='inProgress'>
                  {t(`taskForm.flow.inProgress`)}
                </option>
                <option key='done' value='done'>
                  {t(`taskForm.flow.done`)}
                </option>
              </Form.Select>
            </FloatingLabel>
            {successAlert}
          </Col>
        </Row>
        <Row className='justify-content-md-center'>
          <Col xs={2}>
            <div className='d-grid gap-2'>
              <Button variant='primary' className='col-12' size='lg' type='submit'>
                {t('global.save')}
              </Button>
            </div>
          </Col>
          <Col xs={2}>
            <div className='d-grid gap-2'>
              <Link to={ROUTES.tasks}>
                <Button variant='secondary' className='col-12' size='lg'>
                  {t('global.back')}
                </Button>
              </Link>
            </div>
          </Col>
        </Row>
      </Form>
      {taskId ? (
        <>
          <Row className='justify-content-md-center'>
            <Col xs lg={10}>
              <h2>{t('taskForm.extendedHeader')}</h2>
              <UserSelect userId={assign} action={onAssign} isManager />
              <ImageList imgList={imgList} noAI uploadUrl={'uploadTaskImage/' + taskId} />
            </Col>
          </Row>
        </>
      ) : (
        ''
      )}
    </Container>
  );
});

export default TaskEdit;
