import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Button, Col, Container, FloatingLabel, Form, Row } from 'react-bootstrap';
import { useStores } from 'services/useStore';
import { useTranslation } from 'react-i18next';
import { TSuccessResponseWihNews } from 'services/Api';
import { observer } from 'mobx-react';
import { TLocation } from 'stores/LocationsStore';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from 'components/App/App';
import ImageList from 'components/Heplers/ImageList';
import LinkList from 'components/Heplers/LinkList';
import TextEditor from 'components/Heplers/TextEditor';
import AITextButton from 'components/Buttons/AITextButton';
import DraftCheckBox from 'components/Heplers/DraftCheckBox';
import CreateNewsCheckBox from 'components/Heplers/CreateNewsCheckBox';
import VirtualCheckBox from 'components/Heplers/VirtualCheckBox';
import RedirectCheck from 'components/Heplers/RedirectCheck';
//import styles from './style.module.scss';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let timer: any;

const LocationEdit: React.FC = observer(() => {
  const history = useNavigate();
  const pId = useParams().params || 0;
  const { t } = useTranslation();
  const { api, locationStore } = useStores();
  api.noLogin();
  const [locationId, setLocationId] = useState(+pId);
  const [validated, setValidated] = useState(false);
  const [name, setName] = useState('');
  const [title, setTitle] = useState('');
  const [draft, setDraft] = useState(1);
  const [virtual, setVirtual] = useState(0);
  const [createNews, setCreateNews] = useState(0);
  const [description, setDescription] = useState('');
  const [successText, setSuccessText] = useState('');
  const [imgList, setImgList] = useState([] as string[]);

  const onTitle = useCallback((value: string) => {
    setTitle(value);
  }, []);

  const onCreateNews = useCallback((value: number) => {
    setCreateNews(value);
  }, []);

  const onDraft = useCallback((value: number) => {
    setDraft(value);
  }, []);

  const onVirtual = useCallback((value: number) => {
    setVirtual(value);
  }, []);

  useEffect(() => {
    if (locationId) {
      api.getLocations(locationId).then((data) => {
        const location = data as TLocation;
        locationStore.update(location);
        setName(location.location_name);
        setTitle(location.location_title);
        setDraft(location.location_draft || 0);
        setVirtual(location.location_virtual || 0);
        setDescription(location.location_description || '');
        if (location.location_images) setImgList(location.location_images);
        else setImgList([]);
      });
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const updateImages = useCallback(() => {
    api.getLocations(locationId).then((data) => {
      const location = data as TLocation;
      if (location.location_images) setImgList(location.location_images);
      else setImgList([]);
    });
  }, []);
  const updateLocation = (event: React.FormEvent) => {
    console.log('Save');
    const form = event.currentTarget as HTMLFormElement;
    if (form.checkValidity()) {
      setValidated(true);
      const data: TLocation = {
        location_name: name.trim(),
        location_title: title.trim(),
        location_description: description.trim(),
        location_draft: draft,
        location_virtual: virtual,
        createNews,
      };
      if (locationId) data.location_id = locationId;
      api.setLocation(data).then((res: TSuccessResponseWihNews) => {
        const { errors, success } = res;
        if (!errors) {
          const newsId = success?.news_id || 0;
          api.removeCacheFor(api.settings.url.locations);
          if (locationId || success?.location_id) {
            let dataId = 0;
            if (locationId) dataId = locationId;
            else
              dataId = Number.isInteger(success.location_id) && success ? success.location_id : 0;
            setLocationId(dataId);
            locationStore.update({ data, location_id: dataId });
            api.removeCacheFor(api.settings.url.locations + '/' + dataId);
            api.getLocations(dataId).then((locationData) => {
              const lo: TLocation = locationData as TLocation;
              if (!locationId) locationStore.add({ location_id: dataId, ...lo });
              else locationStore.update(lo);
            });
          } else if (success) {
            //locationStore.add({ location_id: +success, ...data });
            setLocationId(+success.location_id);
            if (newsId) {
              history(ROUTES.locationEdit + '/' + newsId);
            }
          }
          console.log('OK');
          setSuccessText(locationId ? 'locationForm.editSuccess' : 'locationForm.createSuccess');
          timer = setTimeout(() => {
            setSuccessText('');
          }, 5000);
        }
      });
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      event.stopPropagation();
    }

    return false;
  };
  const successAlert = successText ? <Alert variant='success'>{t(successText)}</Alert> : '';
  return (
    <Container fluid className='verticalPadding greenForm'>
      <RedirectCheck url={ROUTES.locations} />
      <Form onSubmit={updateLocation} action='#' validated={validated}>
        <Row className='justify-content-md-center'>
          <Col xs lg={10}>
            <h2>
              {t(locationId ? 'locationForm.edit' : 'locationForm.create')}
              {locationId ? <span className='text-muted'> #{locationId}</span> : ''}
            </h2>
            <FloatingLabel controlId='name' label={t('locationForm.name')} className='mb-3'>
              <Form.Control
                type='text'
                required
                size='lg'
                value={name || ''}
                onChange={(e) => setName(e.target.value)}
                placeholder={t('locationForm.name')}
              />
            </FloatingLabel>
            <FloatingLabel
              controlId='title'
              label={title ? '' : t('locationForm.title')}
              className='mb-3'
            >
              <Form.Control
                as='textarea'
                rows={5}
                size='lg'
                value={title || ''}
                onChange={(e) => setTitle(e.target.value)}
                placeholder={t('locationForm.title')}
                style={{ height: '100px' }}
              />
              <AITextButton handler={onTitle} text={title} />
            </FloatingLabel>
            <TextEditor
              text={description || ''}
              onChange={(value: string) => setDescription(value)}
              fromId={locationId}
              type='location'
              linkStoreName='locationLinks'
              placeholder={t('locationForm.description')}
              linksType={['person', 'stuff']}
            />
            <VirtualCheckBox virtual={virtual} callback={onVirtual} />
            <DraftCheckBox draft={draft} callback={onDraft} />
            <CreateNewsCheckBox draft={draft} createNews={createNews} callback={onCreateNews} />
            {successAlert}
          </Col>
        </Row>
        <Row className='justify-content-md-center'>
          <Col xs={2}>
            <div className='d-grid gap-2'>
              <Button variant='primary' className='col-12' size='lg' type='submit'>
                {t('global.save')}
              </Button>
            </div>
          </Col>
          <Col xs={2}>
            <div className='d-grid gap-2'>
              <Link to={ROUTES.locations}>
                <Button variant='secondary' className='col-12' size='lg'>
                  {t('global.back')}
                </Button>
              </Link>
            </div>
          </Col>
        </Row>
      </Form>
      {locationId ? (
        <>
          <Row className='justify-content-md-center'>
            <Col xs lg={10}>
              <h2>{t('locationForm.extendedHeader')}</h2>
            </Col>
          </Row>
          <Row className='justify-content-md-center mb-3'>
            <Col lg={5}>
              <LinkList
                type='location'
                subType='person'
                id={locationId}
                storeName='locationLinks'
                title={t('person.mainTitle')}
                controll={false}
              />
            </Col>
            <Col lg={5}>
              <LinkList
                type='location'
                subType='stuff'
                id={locationId}
                storeName='locationLinks'
                title={t('stuff.mainTitle')}
                muteLoadFromAPI
                autogenerate
              />
            </Col>
          </Row>
          <Row className='justify-content-md-center mb-3'>
            <Col lg={10}>
              <LinkList
                type='location'
                subType='text'
                id={locationId}
                storeName='locationLinks'
                title={t('text.mainTitle')}
                muteLoadFromAPI
              />
            </Col>
          </Row>
          <Row className='justify-content-md-center mb-3'>
            <Col lg={10}>
              <ImageList
                id={locationId}
                type='location'
                genImgText={title}
                imgList={imgList}
                updateImagesHandle={updateImages}
                uploadUrl={'uploadLocationImage/' + locationId}
              />
            </Col>
          </Row>
        </>
      ) : (
        ''
      )}
    </Container>
  );
});

export default LocationEdit;
