import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Container, FloatingLabel, Form, Row } from 'react-bootstrap';
import { useStores } from 'services/useStore';
import { useTranslation } from 'react-i18next';
import { TSuccessResponse } from 'services/Api';
import { observer } from 'mobx-react';
import { TStuff } from 'stores/StuffsStore';
import { Link, useParams } from 'react-router-dom';
import { ROUTES } from 'components/App/App';
import ImageList from 'components/Heplers/ImageList';
import LinkList from 'components/Heplers/LinkList';
import StuffFolderSelect from 'components/Heplers/StuffFolderSelect';
import UserFind from 'components/Heplers/UserFind';
import UserShow from 'components/Heplers/UserShow';
import VirtualCheckBox from 'components/Heplers/VirtualCheckBox';
import RedirectCheck from 'components/Heplers/RedirectCheck';
//import styles from './style.module.scss';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let timer: any;

const StuffEdit: React.FC = observer(() => {
  const pId = useParams().params || 0;
  const { t } = useTranslation();
  const { api, stuffStore, sessionStore } = useStores();
  api.noLogin();
  const [stuffId, setStuffId] = useState(+pId);
  const [validated, setValidated] = useState(false);
  const [name, setName] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [source, setSource] = useState('');
  const [price, setPrice] = useState(0);
  const [folder, setFolder] = useState(0);
  const [virtual, setVirtual] = useState(0);
  const [userId, setUserId] = useState<number>(sessionStore.session.user_id);
  const [shared, setShared] = useState('');
  const [status, setStatus] = useState('');
  const [successText, setSuccessText] = useState('');
  const [imgList, setImgList] = useState([] as string[]);

  useEffect(() => {
    if (stuffId) {
      api.getStuffs(stuffId).then((data) => {
        const stuff = data as TStuff;
        stuffStore.update(stuff);
        setName(stuff.stuff_name);
        setTitle(stuff.stuff_title);
        setDescription(stuff.stuff_description || '');
        setSource(stuff.stuff_source || '');
        setStatus(stuff.stuff_status || '');
        setPrice(stuff.stuff_price || 0);
        setVirtual(stuff.stuff_virtual || 0);
        setFolder(stuff.folder_id || 0);
        setShared(stuff.stuff_shared || '');
        if (stuff.user_id) setUserId(stuff.user_id);
        if (stuff.stuff_images) setImgList(stuff.stuff_images);
        else setImgList([]);
      });
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateStuff = (event: React.FormEvent) => {
    const form = event.currentTarget as HTMLFormElement;
    if (form.checkValidity()) {
      setValidated(true);
      const data: TStuff = {
        stuff_name: name.trim(),
        stuff_title: title.trim(),
        stuff_description: description.trim(),
        stuff_source: source.trim(),
        stuff_status: status.trim(),
        stuff_price: +price,
        folder_id: folder,
        stuff_shared: shared,
        stuff_virtual: virtual,
        user_id: userId,
        project_id: sessionStore.session.user_project,
      };
      if (stuffId) data.stuff_id = stuffId;
      api.setStuff(data).then((res: TSuccessResponse) => {
        const { errors, success } = res;
        if (!errors) {
          api.removeCacheFor(api.settings.url.stuffs);
          if (stuffId || (Number.isInteger(success) && success)) {
            let dataId = 0;
            if (stuffId) dataId = stuffId;
            else dataId = Number.isInteger(success) && success ? +success : 0;
            setStuffId(dataId);
            api.removeCacheFor(api.settings.url.stuffs + '/' + dataId);
            api.getStuffs(dataId).then((stuffData) => {
              const st: TStuff = stuffData as TStuff;
              if (!stuffId) stuffStore.add({ stuff_id: dataId, ...st });
              else stuffStore.update(st);
            });
          } else if (success) {
            //stuffStore.add({ stuff_id: +success, ...data });
            setStuffId(+success);
          }
          console.log('OK');
          setSuccessText(stuffId ? 'stuffForm.editSuccess' : 'stuffForm.createSuccess');
          timer = setTimeout(() => {
            setSuccessText('');
          }, 5000);
        }
      });
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      event.stopPropagation();
    }

    return false;
  };
  const successAlert = successText ? <Alert variant='success'>{t(successText)}</Alert> : '';
  return (
    <Container fluid className='verticalPadding greenForm'>
      <RedirectCheck url={ROUTES.stuffs} />
      <Form onSubmit={updateStuff} action='#' validated={validated}>
        <Row className='justify-content-md-center mb-5'>
          <Col xs lg={10}>
            <h2>
              {t(stuffId ? 'stuffForm.edit' : 'stuffForm.create')}
              {stuffId ? <span className='text-muted'> #{stuffId}</span> : ''}
            </h2>
            <StuffFolderSelect folderId={folder} action={setFolder} addRoot />
            <FloatingLabel controlId='name' label={t('stuffForm.name')} className='mb-3'>
              <Form.Control
                type='text'
                required
                size='lg'
                value={name || ''}
                onChange={(e) => setName(e.target.value)}
                placeholder={t('stuffForm.name')}
              />
            </FloatingLabel>
            <FloatingLabel
              controlId='title'
              label={title ? '' : t('stuffForm.title')}
              className='mb-3'
            >
              <Form.Control
                as='textarea'
                rows={5}
                size='lg'
                value={title || ''}
                onChange={(e) => setTitle(e.target.value)}
                placeholder={t('stuffForm.title')}
                style={{ height: '100px' }}
              />
            </FloatingLabel>
            <FloatingLabel
              controlId='description'
              label={description ? '' : t('stuffForm.description')}
              className='mb-3'
            >
              <Form.Control
                as='textarea'
                rows={5}
                size='lg'
                value={description || ''}
                onChange={(e) => setDescription(e.target.value)}
                placeholder={t('stuffForm.description')}
                style={{ height: '100px' }}
              />
            </FloatingLabel>
            <VirtualCheckBox virtual={virtual} callback={setVirtual} />
            {!virtual ? (
              <>
                <FloatingLabel
                  controlId='source'
                  label={source ? '' : t('stuffForm.source')}
                  className='mb-3'
                >
                  <Form.Control
                    as='textarea'
                    rows={5}
                    size='lg'
                    value={source || ''}
                    onChange={(e) => setSource(e.target.value)}
                    placeholder={t('stuffForm.source')}
                    style={{ height: '100px' }}
                  />
                </FloatingLabel>
                <FloatingLabel controlId='price' label={t('stuffForm.price')} className='mb-3'>
                  <Form.Control
                    type='number'
                    size='lg'
                    value={price || 0}
                    onChange={(e) => setPrice(+e.target.value)}
                    placeholder={t('stuffForm.price')}
                  />
                </FloatingLabel>
                <FloatingLabel controlId='status' label={t('stuffForm.status')} className='mb-3'>
                  <Form.Control
                    type='text'
                    size='lg'
                    value={status || ''}
                    onChange={(e) => setStatus(e.target.value)}
                    placeholder={t('stuffForm.status')}
                  />
                </FloatingLabel>

                <Form.Check
                  type='switch'
                  key='createNews'
                  name='createNews'
                  label={t('stuffForm.shared')}
                  checked={shared === 'share'}
                  onChange={(e) => setShared(e.target.checked ? 'share' : '')}
                />

                {shared === 'share' ? (
                  <>
                    <Row className='mt-5'>
                      <Col>
                        <h5>{t('stuffForm.owner')}</h5>
                      </Col>
                    </Row>
                    <Row className='mb-3'>
                      <Col xs={3}>
                        <UserShow isSearch linkInDialog userId={userId} hideEmpty />
                      </Col>
                      <Col>
                        <UserFind action={(id) => setUserId(id)} />
                      </Col>
                    </Row>
                  </>
                ) : (
                  ''
                )}
              </>
            ) : (
              ''
            )}
            {successAlert}
          </Col>
        </Row>
        <Row className='justify-content-md-center'>
          <Col xs={2}>
            <div className='d-grid gap-2'>
              <Button variant='primary' className='col-12' size='lg' type='submit'>
                {t('global.save')}
              </Button>
            </div>
          </Col>
          <Col xs={2}>
            <div className='d-grid gap-2'>
              <Link to={ROUTES.stuffs}>
                <Button variant='secondary' className='col-12' size='lg'>
                  {t('global.back')}
                </Button>
              </Link>
            </div>
          </Col>
        </Row>
      </Form>
      {stuffId ? (
        <>
          <Row className='justify-content-md-center'>
            <Col xs lg={10}>
              <h2>{t('stuffForm.extendedHeader')}</h2>
            </Col>
          </Row>
          <Row className='justify-content-md-center mb-3'>
            <Col lg={3}>
              <LinkList
                type='stuff'
                subType='person'
                id={stuffId}
                storeName='stuffLinks'
                title={t('person.mainTitle')}
                autogenerate
                linkInDialog
              />
            </Col>
            <Col lg={4}>
              <LinkList
                type='stuff'
                subType='location'
                id={stuffId}
                storeName='stuffLinks'
                title={t('location.mainTitle')}
                muteLoadFromAPI
                autogenerate
                linkInDialog
              />
            </Col>
            <Col lg={3}>
              <LinkList
                type='stuff'
                subType='quest'
                id={stuffId}
                storeName='stuffLinks'
                title={t('quest.mainTitle')}
                muteLoadFromAPI
                autogenerate
                linkInDialog
              />
            </Col>
          </Row>
          <Row className='justify-content-md-center mb-3'>
            <Col lg={10}>
              <ImageList imgList={imgList} noAI uploadUrl={'uploadStuffImage/' + stuffId} />
            </Col>
          </Row>
        </>
      ) : (
        ''
      )}
    </Container>
  );
});

export default StuffEdit;
