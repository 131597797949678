import React, { useEffect, useState, useCallback } from 'react';
import { Alert, Button, Col, Container, FloatingLabel, Form, Row } from 'react-bootstrap';
import { useStores } from 'services/useStore';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { TText } from 'stores/TextsStore';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from 'components/App/App';
import ImageList from 'components/Heplers/ImageList';
import UserSelect from 'components/Heplers/UserSelect';
import LinkList from 'components/Heplers/LinkList';
import TextFolderSelect from 'components/Heplers/TextFolderSelect';
import AITextButton from 'components/Buttons/AITextButton';
import DraftCheckBox from 'components/Heplers/DraftCheckBox';
import InfoButton from 'components/Heplers/InfoButton';
import CreateNewsCheckBox from 'components/Heplers/CreateNewsCheckBox';
import { TSuccessResponseWihNews } from 'services/Api';
import RedirectCheck from 'components/Heplers/RedirectCheck';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let timer: any;

const TextEdit: React.FC = observer(() => {
  const pId = useParams().params || 0;
  const history = useNavigate();
  const { t } = useTranslation();
  const { api, textStore } = useStores();
  api.noLogin();
  const [textId, setTextId] = useState(+pId);
  const [validated, setValidated] = useState(false);
  const [description, setDescription] = useState('');
  const [name, setName] = useState('');
  const [status, setStatus] = useState('');
  const [master, setMaster] = useState(0);
  const [folder, setFolder] = useState(0);
  const [draft, setDraft] = useState(1);
  const [createNews, setCreateNews] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [successText, setSuccessText] = useState('');
  const [imgList, setImgList] = useState([] as string[]);

  const onDescription = useCallback((value: string) => {
    setDescription(value);
  }, []);

  const onCreateNews = useCallback((value: number) => {
    setCreateNews(value);
  }, []);

  const onDraft = useCallback((value: number) => {
    setDraft(value);
  }, []);

  useEffect(() => {
    if (textId) {
      api.getTexts(textId).then((data) => {
        const text = data as TText;
        setDescription(text.text_description || '');
        setStatus(text.text_status || '');
        setName(text.text_name || '');
        setMaster(text.text_master || 0);
        setFolder(text.folder_id || 0);
        setDraft(text.text_draft || 0);
        textStore.update(text);
        if (text.text_images) setImgList(text.text_images);
        else setImgList([]);
        if (text.text_status && text.text_status?.indexOf('open') > -1) {
          setIsOpen(true);
        } else setIsOpen(false);
      });
    } else {
      setFolder(+window.localStorage.textFolder || 0);
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeOpen = () =>
    status
      .replace('open ,', '')
      .replace(', open', '')
      .replace(' open', '')
      .replace('open ', '')
      .replace('open', '')
      .trim();

  useEffect(() => {
    if (status && status?.indexOf('open') > -1) {
      if (!isOpen) {
        setStatus(removeOpen());
      }
    } else if (isOpen) {
      const newStatus = status ? status + ', open' : 'open';
      setStatus(newStatus);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (status && status?.indexOf('open') > -1) {
      if (draft) {
        setStatus(removeOpen());
        setIsOpen(false);
      } else if (!isOpen) setIsOpen(true);
    } else if (isOpen) setIsOpen(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    if (draft) {
      setStatus(removeOpen());
      setIsOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draft]);

  const updateImages = useCallback(() => {
    api.getTexts(textId).then((data) => {
      const text = data as TText;
      if (text.text_images) setImgList(text.text_images);
      else setImgList([]);
    });
  }, []);
  const updateText = (event: React.FormEvent) => {
    const form = event.currentTarget as HTMLFormElement;
    if (form.checkValidity()) {
      setValidated(true);
      const data: TText = {
        text_description: description.trim(),
        text_status: status.trim(),
        text_master: master,
        text_name: name.trim(),
        text_draft: draft,
        folder_id: folder,
        createNews,
      };
      if (textId) data.text_id = textId;
      api.setText(data).then((res: TSuccessResponseWihNews) => {
        const { errors, success } = res;
        if (!errors) {
          const newsId = success?.news_id || 0;
          api.removeCacheFor(api.settings.url.texts);
          if (textId || success?.text_id) {
            let dataId = 0;
            if (textId) dataId = textId;
            else dataId = success?.text_id || 0;
            setTextId(dataId);
            api.removeCacheFor(api.settings.url.texts + '/' + dataId);
            api.getTexts(dataId).then((textData) => {
              const lo: TText = textData as TText;
              setMaster(lo.text_master || 0);
              if (!textId) textStore.add({ text_id: dataId, ...lo });
              else textStore.update(lo);
              if (newsId) {
                history(ROUTES.newsEdit + '/' + newsId);
              }
            });
          } else if (success) {
            //textStore.add({ text_id: +success, ...data });
            setTextId(+success.text_id);
            if (newsId) {
              history(ROUTES.newsEdit + '/' + newsId);
            }
          }
          console.log('OK');
          setSuccessText(textId ? 'textForm.editSuccess' : 'textForm.createSuccess');
          timer = setTimeout(() => {
            setSuccessText('');
          }, 5000);
        }
      });
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      event.stopPropagation();
    }

    return false;
  };
  const successAlert = successText ? <Alert variant='success'>{t(successText)}</Alert> : '';
  return (
    <Container fluid className='verticalPadding greenForm'>
      <RedirectCheck url={ROUTES.texts} />
      <Form onSubmit={updateText} action='#' validated={validated}>
        <Row className='justify-content-md-center'>
          <Col xs lg={10}>
            <h2>
              {t(textId ? 'textForm.edit' : 'textForm.create')}
              {textId ? <span className='text-muted'> #{textId}</span> : ''}
            </h2>
            <TextFolderSelect folderId={folder} action={setFolder} addRoot />
            <FloatingLabel controlId='name' label={t('textForm.name')} className='mb-3'>
              <Form.Control
                type='text'
                size='lg'
                value={name || ''}
                onChange={(e) => setName(e.target.value)}
                placeholder={t('textForm.name')}
              />
            </FloatingLabel>
            <FloatingLabel
              controlId='description'
              label={description ? '' : t('textForm.description')}
              className='mb-3'
            >
              <Form.Control
                as='textarea'
                rows={5}
                size='lg'
                value={description || ''}
                onChange={(e) => setDescription(e.target.value)}
                placeholder={t('textForm.description')}
                style={{ height: '450px' }}
              />
              <AITextButton handler={onDescription} text={description} />
            </FloatingLabel>
            <Row>
              <Col xs lg={11}>
                <FloatingLabel controlId='status' label={t('textForm.status')} className='mb-3'>
                  <Form.Control
                    type='text'
                    size='lg'
                    value={status || ''}
                    onChange={(e) => setStatus(e.target.value)}
                    placeholder={t('textForm.status')}
                  />
                </FloatingLabel>
              </Col>
              <Col xs lg={1}>
                <InfoButton text='textStatus' />
              </Col>
            </Row>
            <Form.Check
              type='switch'
              key='open'
              name='open'
              label={t('textForm.open')}
              checked={isOpen}
              disabled={!!draft}
              onChange={(e) => setIsOpen(e.target.checked)}
            />
            <DraftCheckBox draft={draft} callback={onDraft} />
            <CreateNewsCheckBox draft={draft} createNews={createNews} callback={onCreateNews} />

            {successAlert}
          </Col>
        </Row>
        <Row className='justify-content-md-center'>
          <Col xs={2}>
            <div className='d-grid gap-2'>
              <Button variant='primary' className='col-12' size='lg' type='submit'>
                {t('global.save')}
              </Button>
            </div>
          </Col>
          <Col xs={2}>
            <div className='d-grid gap-2'>
              <Link to={ROUTES.texts}>
                <Button variant='secondary' className='col-12' size='lg'>
                  {t('global.back')}
                </Button>
              </Link>
            </div>
          </Col>
        </Row>
      </Form>
      {textId ? (
        <>
          <Row className='justify-content-md-center mb-3'>
            <Col xs lg={10}>
              <h2>{t('textForm.extendedHeader')}</h2>
              <UserSelect userId={master} action={setMaster} isManager />
            </Col>
          </Row>
          <Row className='justify-content-md-center mb-3'>
            <Col lg={5}>
              <LinkList
                type='text'
                subType='person'
                id={textId}
                storeName='textLinks'
                title={t('person.mainTitle')}
              />
            </Col>
            <Col lg={5}>
              <LinkList
                type='text'
                subType='location'
                id={textId}
                storeName='textLinks'
                title={t('location.mainTitle')}
                muteLoadFromAPI
              />
            </Col>
          </Row>
          <Row className='justify-content-md-center mb-3'>
            <Col lg={5}>
              <LinkList
                type='text'
                subType='status'
                id={textId}
                storeName='textLinks'
                title={t('status.mainTitle')}
                muteLoadFromAPI
              />
            </Col>
            <Col lg={5}>
              <LinkList
                type='text'
                subType='project'
                id={textId}
                storeName='textLinks'
                title={t('projects.mainTitle')}
                muteLoadFromAPI
              />
            </Col>
          </Row>
          <Row className='justify-content-md-center mb-3'>
            <Col lg={10}>
              <ImageList
                id={textId}
                type='text'
                genImgText={description}
                imgList={imgList}
                updateImagesHandle={updateImages}
                uploadUrl={'uploadTextImage/' + textId}
              />
            </Col>
          </Row>
        </>
      ) : (
        ''
      )}
    </Container>
  );
});

export default TextEdit;
