import React, { useEffect, useState, useCallback } from 'react';
import { Alert, Button, Col, Container, FloatingLabel, Form, Row } from 'react-bootstrap';
import { useStores } from 'services/useStore';
import { useTranslation } from 'react-i18next';
import { TSuccessResponse } from 'services/Api';
import { observer } from 'mobx-react';
import { QuestType, QuestTypes, TQuest } from 'stores/QuestsStore';
import { Link, useParams } from 'react-router-dom';
import { ROUTES } from 'components/App/App';
import ImageList from 'components/Heplers/ImageList';
import UserSelect from 'components/Heplers/UserSelect';
import LinkList from 'components/Heplers/LinkList';
import TextEditor from 'components/Heplers/TextEditor';
import AITextButton from 'components/Buttons/AITextButton';
import DraftCheckBox from 'components/Heplers/DraftCheckBox';
import InfoButton from 'components/Heplers/InfoButton';
import RedirectCheck from 'components/Heplers/RedirectCheck';
//import styles from './style.module.scss';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let timer: any;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const QuestEdit: React.FC<any> = observer((routerProps) => {
  const pId = useParams().params || 0;
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const plot = (routerProps as any).plot || false;
  const { api, questStore } = useStores();
  api.noLogin();
  const [questId, setQuestId] = useState(+pId);
  const [validated, setValidated] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [status, setStatus] = useState('');
  const [type, setType] = useState('');
  const [master, setMaster] = useState(0);
  const [draft, setDraft] = useState(1);
  const [successText, setSuccessText] = useState('');
  const [imgList, setImgList] = useState([] as string[]);

  const onDescription = useCallback((value: string) => {
    setDescription(value);
  }, []);

  const onDraft = useCallback((value: number) => {
    setDraft(value);
  }, []);

  useEffect(() => {
    if (questId) {
      api.getQuests(questId).then((data) => {
        const quest = data as TQuest;
        setName(quest.quest_name);
        setType(plot ? QuestType.Plot : quest.quest_type || QuestType.Plot);
        setDescription(quest.quest_description || '');
        setStatus(quest.quest_status || '');
        setMaster(quest.quest_master || 0);
        setDraft(quest.quest_draft || 0);
        questStore.update(quest);
        if (quest.quest_images) setImgList(quest.quest_images);
        else setImgList([]);
      });
    } else {
      setType(plot ? QuestType.Plot : QuestType.Quest);
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const updateImages = useCallback(() => {
    api.getQuests(questId).then((data) => {
      const quest = data as TQuest;
      if (quest.quest_images) setImgList(quest.quest_images);
      else setImgList([]);
    });
  }, []);
  const updateQuest = (event: React.FormEvent) => {
    const form = event.currentTarget as HTMLFormElement;
    if (form.checkValidity()) {
      setValidated(true);
      const data: TQuest = {
        quest_name: name.trim(),
        quest_type: type,
        quest_description: description.trim(),
        quest_status: status.trim(),
        quest_master: master,
        quest_draft: draft,
      };
      if (questId) data.quest_id = questId;
      api.setQuest(data).then((res: TSuccessResponse) => {
        const { errors, success } = res;
        if (!errors) {
          api.removeCacheFor(api.settings.url.quests);
          if (questId || (Number.isInteger(success) && success)) {
            let dataId = 0;
            if (questId) dataId = questId;
            else dataId = Number.isInteger(success) && success ? +success : 0;
            setQuestId(dataId);
            api.removeCacheFor(api.settings.url.quests + '/' + dataId);
            api.getQuests(dataId).then((questData) => {
              const lo: TQuest = questData as TQuest;
              setMaster(lo.quest_master || 0);
              if (!questId) questStore.add({ quest_id: dataId, ...lo });
              else questStore.update(lo);
            });
          } else if (success) {
            //questStore.add({ quest_id: +success, ...data });
            setQuestId(+success);
          }
          console.log('OK');
          setSuccessText(questId ? 'questForm.editSuccess' : 'questForm.createSuccess');
          timer = setTimeout(() => {
            setSuccessText('');
          }, 5000);
        }
      });
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      event.stopPropagation();
    }

    return false;
  };
  const successAlert = successText ? <Alert variant='success'>{t(successText)}</Alert> : '';
  const tr = plot ? 'plotForm.' : 'questForm.';
  return (
    <Container fluid className='verticalPadding greenForm'>
      <RedirectCheck url={ROUTES.quests} />
      <Form onSubmit={updateQuest} action='#' validated={validated}>
        <Row className='justify-content-md-center'>
          <Col xs lg={10}>
            <h2>
              {t(questId ? tr + 'edit' : tr + 'create')}
              {questId ? <span className='text-muted'> #{questId}</span> : ''}
            </h2>
            <FloatingLabel controlId='name' label={t(tr + 'name')} className='mb-3'>
              <Form.Control
                type='text'
                required
                size='lg'
                value={name || ''}
                onChange={(e) => setName(e.target.value)}
                placeholder={t(tr + 'name')}
              />
            </FloatingLabel>
            {plot ? (
              ''
            ) : (
              <FloatingLabel controlId='type' label={t(tr + 'type')} className='mb-3'>
                <Form.Select
                  aria-label={t(tr + 'type')}
                  required
                  size='lg'
                  value={type}
                  onChange={(e) => setType(e.currentTarget.value)}
                >
                  {QuestTypes.map((questType) => (
                    <option key={questType} value={questType}>
                      {t(`questForm.types.${questType}`)}
                    </option>
                  ))}
                </Form.Select>
              </FloatingLabel>
            )}
            <div style={{ position: 'relative' }}>
              <TextEditor
                text={description || ''}
                onChange={(value: string) => setDescription(value)}
                fromId={questId}
                type='quest'
                linkStoreName='questLinks'
                placeholder={t(tr + 'description')}
                linksType={['person', 'stuff']}
              />
              <AITextButton handler={onDescription} text={description} offset={20} />
            </div>
            <Row>
              <Col xs lg={11}>
                <FloatingLabel controlId='status' label={t(tr + 'status')} className='mb-3'>
                  <Form.Control
                    type='text'
                    size='lg'
                    value={status || ''}
                    onChange={(e) => setStatus(e.target.value)}
                    placeholder={t(tr + 'status')}
                  />
                </FloatingLabel>
              </Col>
              <Col xs lg={1}>
                <InfoButton text='questStatus' />
              </Col>
            </Row>
            <DraftCheckBox draft={draft} callback={onDraft} />
            {successAlert}
          </Col>
        </Row>
        <Row className='justify-content-md-center'>
          <Col xs={2}>
            <div className='d-grid gap-2'>
              <Button variant='primary' className='col-12' size='lg' type='submit'>
                {t('global.save')}
              </Button>
            </div>
          </Col>
          <Col xs={2}>
            <div className='d-grid gap-2'>
              <Link to={plot ? ROUTES.plots : ROUTES.quests}>
                <Button variant='secondary' className='col-12' size='lg'>
                  {t('global.back')}
                </Button>
              </Link>
            </div>
          </Col>
        </Row>
      </Form>
      {questId ? (
        <>
          <Row className='justify-content-md-center mb-3'>
            <Col lg={10}>
              <h2>{t(tr + 'extendedHeader')}</h2>
              <UserSelect userId={master} action={setMaster} isManager />
            </Col>
          </Row>
          <Row className='justify-content-md-center mb-3'>
            <Col lg={5}>
              <LinkList
                type='quest'
                subType='person'
                id={questId}
                storeName='questLinks'
                title={t('person.mainTitle')}
                autogenerate
              />
            </Col>
            <Col lg={5}>
              <LinkList
                type='quest'
                subType='stuff'
                id={questId}
                storeName='questLinks'
                title={t('stuff.mainTitle')}
                muteLoadFromAPI
                autogenerate
              />
            </Col>
          </Row>
          <Row className='justify-content-md-center mb-3'>
            <Col lg={10}>
              <ImageList
                id={questId}
                type='quest'
                genImgText={description}
                imgList={imgList}
                updateImagesHandle={updateImages}
                uploadUrl={'uploadQuestImage/' + questId}
              />
            </Col>
          </Row>
        </>
      ) : (
        ''
      )}
    </Container>
  );
});

export default QuestEdit;
