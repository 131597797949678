import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Alert, Badge, Button, Card, Col, ListGroup, Modal, Row } from 'react-bootstrap';
import { Gift, Shield } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { useStores } from 'services/useStore';

interface IPricingDialog {
  onlyView?: boolean;
  show?: boolean;
  hideCallback?: () => void;
}

const PricingDialog: React.FC<IPricingDialog> = observer(
  ({ hideCallback, show, onlyView = false }) => {
    const { t } = useTranslation();
    const { api, sessionStore } = useStores();
    const [canFreeProject, setCanFree] = useState<boolean>(false);
    const [isAIGenerated, setIsAIGenerated] = useState<boolean>(false);
    const selectPricing = (name: string) => {
      if (sessionStore.appSettings?.pricingShow !== false) {
        sessionStore.appSettings?.pricingShow.callback(name);
        api.togglePricingGenerateDialog(false);
      }
    };
    const hide = () => {
      if (onlyView && hideCallback) {
        hideCallback();
        return;
      }
      if (sessionStore.appSettings?.pricingShow !== false) {
        sessionStore.appSettings?.pricingShow.callback(false);
      }
      api.togglePricingGenerateDialog(false);
    };
    useEffect(() => {
      if (window.location.href.indexOf('projectAILARP') > -1) {
        setIsAIGenerated(true);
        return;
      }
      api.canFreeProject().then((canFree: boolean) => {
        if (canFree) setCanFree(true);
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    //
    //const currentPricing = 'free';
    return (
      <Modal
        show={sessionStore.appSettings?.pricingShow !== false || (onlyView && show)}
        size='xl'
        key='editModal'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        onHide={hide}
      >
        <Modal.Header>{t('pricing.header')}</Modal.Header>
        <Modal.Body style={{ backgroundColor: '#0000001c' }}>
          <Row>
            {(isAIGenerated ? ['mini', 'full'] : ['free', 'mini', 'full']).map((name: string) => (
              <Col key={name} style={{ width: 300 }}>
                <Card>
                  <Card.Body>
                    <Card.Title>{t(`pricing.${name}.name`)}</Card.Title>
                    {/*<Card.Subtitle className='mb-2 text-muted'>
                    {currentPricing === name ? <>[{t('pricing.yourCurrent')}]</> : <>&nbsp;</>}
          </Card.Subtitle>*/}
                    <Card.Text style={{ height: 100 }}>
                      {t(`pricing.${name}.description`)}
                    </Card.Text>
                    <ListGroup>
                      <ListGroup.Item className='d-flex justify-content-between '>
                        {t(`pricing.${name}.line10`)}{' '}
                        <Badge bg={name === 'free' ? 'warning' : 'success'} pill>
                          {t(`pricing.${name}.result10`)}
                        </Badge>
                      </ListGroup.Item>
                      <ListGroup.Item className='d-flex justify-content-between '>
                        {t(`pricing.${name}.line1`)}{' '}
                        <Badge bg={name === 'free' || name === 'mini' ? 'warning' : 'success'} pill>
                          {t(`pricing.${name}.result1`)}
                        </Badge>
                      </ListGroup.Item>
                      <ListGroup.Item className='d-flex justify-content-between '>
                        {t(`pricing.${name}.line2`)}{' '}
                        <Badge bg={name === 'free' ? 'warning' : 'success'} pill>
                          {t(`pricing.${name}.result2`)}
                        </Badge>
                      </ListGroup.Item>
                      <ListGroup.Item className='d-flex justify-content-between '>
                        {t(`pricing.${name}.line3`)}{' '}
                        <Badge bg={name === 'free' ? 'warning' : 'success'} pill>
                          {t(`pricing.${name}.result3`)}
                        </Badge>
                      </ListGroup.Item>
                      <ListGroup.Item className='d-flex justify-content-between '>
                        {t(`pricing.${name}.line4`)}{' '}
                        <Badge bg={name === 'free' ? 'warning' : 'success'} pill>
                          {t(`pricing.${name}.result4`)}
                        </Badge>
                      </ListGroup.Item>
                      <ListGroup.Item className='d-flex justify-content-between '>
                        {t(`pricing.${name}.line5`)}{' '}
                        <Badge bg={name === 'free' ? 'warning' : 'success'} pill>
                          {t(`pricing.${name}.result5`)}
                        </Badge>
                      </ListGroup.Item>
                      <ListGroup.Item className='d-flex justify-content-between '>
                        {t(`pricing.${name}.line6`)}{' '}
                        <Badge bg={name === 'free' ? 'warning' : 'success'} pill>
                          {t(`pricing.${name}.result6`)}
                        </Badge>
                      </ListGroup.Item>
                      <ListGroup.Item className='d-flex justify-content-between '>
                        {t(`pricing.${name}.line9`)}{' '}
                        <Badge bg={name === 'free' ? 'warning' : 'success'} pill>
                          {t(`pricing.${name}.result9`)}
                        </Badge>
                      </ListGroup.Item>
                      <ListGroup.Item className='d-flex justify-content-between '>
                        {t(`pricing.${name}.line7`)} *
                        <Badge bg='success' pill>
                          {t(`pricing.${name}.result7`)}
                        </Badge>
                      </ListGroup.Item>
                      <ListGroup.Item className='d-flex justify-content-between '>
                        {t(`pricing.${name}.line8`)}{' '}
                        <Badge bg={name === 'free' ? 'danger' : 'success'} pill>
                          {t(`pricing.${name}.result8`)}
                        </Badge>
                      </ListGroup.Item>
                      <ListGroup.Item className='d-flex justify-content-between '>
                        {t(`pricing.${name}.line11`)}{' '}
                        <Badge bg={name === 'free' ? 'danger' : 'success'} pill>
                          {t(`pricing.${name}.result11`)}
                        </Badge>
                      </ListGroup.Item>
                    </ListGroup>
                    <Card.Title>
                      {api.settings.pricing[name as keyof typeof api.settings.pricing] ? (
                        <span style={{ textDecoration: 'line-through' }}>
                          {api.settings.pricing[name as keyof typeof api.settings.pricing]}
                          {t('pricing.price')}
                        </span>
                      ) : (
                        t('pricing.freePrice')
                      )}
                    </Card.Title>

                    {!onlyView ? (
                      <div className='d-grid gap-2'>
                        {(name === 'free' && canFreeProject) ||
                        name !== 'free' ||
                        (sessionStore.appSettings?.pricingShow &&
                          sessionStore.appSettings?.pricingShow?._default === 'free') ? (
                          <Button
                            variant={
                              api.settings.pricing[name as keyof typeof api.settings.pricing] <=
                              sessionStore.session.user_balance
                                ? 'primary'
                                : 'warning'
                            }
                            size='sm'
                            onClick={() => selectPricing(name)}
                          >
                            {t(`pricing.select`)}
                          </Button>
                        ) : (
                          <div style={{ height: 31 }}>{t('pricing.noFree')}</div>
                        )}
                      </div>
                    ) : (
                      ''
                    )}
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
          <Row>
            <Col className='mt-3'>
              <Alert
                variant='info'
                style={{
                  fontSize: 20,
                  fontWeight: 'bold',
                  backgroundImage: 'linear-gradient(180deg, #cff4fc, #fcfbcf)',
                }}
              >
                <Shield
                  style={{
                    marginTop: -5,
                    marginRight: 10,
                  }}
                />{' '}
                {t(`billing.zsu`)}
              </Alert>
              <Alert variant='success' style={{ fontSize: 20, fontWeight: 'bold' }}>
                <Gift style={{ marginTop: -5, marginRight: 10 }} /> {t(`billing.subscribeFree`)}
              </Alert>
            </Col>
          </Row>
          <Row>
            <Col className='text-muted'>{t(`pricing.genTextNotice`)}</Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  }
);
export default PricingDialog;
