import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AddButton from 'components/Buttons/AddButton';
import ItemViewer from 'components/Heplers/ItemViewer';
import { TNews } from 'stores/NewsStore';
import UserShow from 'components/Heplers/UserShow';
import { ShowTypes } from 'services/Api';
import { Badge } from 'react-bootstrap';
import MarkdownText from 'components/Heplers/MarkdownText';
import { useStores } from '../../services/useStore';

const NewsList: React.FC = observer(() => {
  const { t } = useTranslation();
  const { sessionStore, newsStore, api } = useStores();
  api.noLogin();
  const [inited, setInit] = useState(false);
  let { data } = newsStore;
  if (!data) data = [];

  useEffect(() => {
    api.getNews().then(() => {
      setInit(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const adminActions =
    api.isManager(sessionStore.session.user_project) && api.projectIsActive() ? (
      <AddButton title={t('news.newNews')} link='newsEdit' />
    ) : (
      ''
    );
  const columns = [
    {
      dataField: 'news_created',
      text: t('newsForm.date'),
      sort: true,
      headerStyle: { width: 150 },
      style: { fontSize: '0.8rem' },
      formatter: (cell: undefined, row: TNews) => (
        <div>
          <Badge bg={row.news_visibility === 'all' ? 'info' : 'secondary'}>
            {t(row.news_visibility === 'all' ? 'news.visibilityAll' : 'news.visibilityProject')}
          </Badge>
          <div>{api.timeFormat(row.news_created)}</div>
        </div>
      ),
    },
    {
      dataField: 'news_description',
      text: t('newsForm.description'),
      sort: false,
      formatter: (cell: undefined, row: TNews) => (
        <div style={{ overflow: 'hidden', lineHeight: 1.1 }}>
          {row.img_id ? (
            <img
              style={{ height: 88, float: 'left', marginRight: 8, borderRadius: 5 }}
              src={`${api.settings.url.server}img/${row.img_id}.${row.img_mime}`}
              alt={row.news_created}
            />
          ) : (
            ''
          )}
          <MarkdownText text={row.news_description.substring(0, 400)} />
        </div>
      ),
    },
    {
      dataField: 'user_id',
      text: t('global.master'),
      sort: true,
      headerStyle: { width: 150 },
      formatter: (cell: undefined, row: TNews) => (
        <div style={{ overflow: 'hidden' }}>
          <UserShow userId={row.user_id} linkInDialog />
        </div>
      ),
    },
  ];
  const newsFilter = (n: TNews) => {
    return !n.project_id || n.project_id === sessionStore.session.user_project;
  };
  if (!inited) {
    return <div>{t('loading')}</div>;
  }
  return (
    <ItemViewer
      id='news_id'
      store='newsStore'
      type='news'
      tableData={data.filter(newsFilter)}
      columns={columns}
      showRoute='news'
      editRoute='newsEdit'
      name=''
      title='news_description'
      draftField='news_draft'
      canEdit={(newsRow: TNews) => api.isManager(newsRow.project_id)}
      mainTitle={t('news.mainTitle')}
      adminActions={adminActions}
      staticShowType={ShowTypes.List}
    />
  );
});
export default NewsList;
