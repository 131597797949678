/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'services/useStore';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { ProjectPayTypes, TProject } from 'stores/ProjectsStore';
import { ROUTES } from 'components/App/App';
import { Gift } from 'react-bootstrap-icons';

export interface ISubscribeFreeDialog {
  projectId: number;
  show: boolean;
  callback?: () => void;
  hideCallback: () => void;
}

const SubscribeFreeDialog: React.FC<ISubscribeFreeDialog> = observer(
  ({ projectId, show, callback, hideCallback }) => {
    const { t } = useTranslation();
    const [pricingName, setPricingName] = useState('');
    const { api, projectStore, sessionStore } = useStores();
    useEffect(() => {
      if (!show || !projectId) return;
      const project: TProject = projectStore.get(projectId);
      const price = project.project_pricing;
      setPricingName(price);
    }, [projectId, show]);
    const activate = () => {
      api.projectActivateFree(projectId).then(() => {
        window.location.href = ROUTES.projectEditLARP + '/' + projectId;
      });
    };
    return (
      <Modal show={show} size='sm' key='editModal' onHide={hideCallback}>
        <Modal.Header closeButton>{t('billing.subscribe')}</Modal.Header>
        <Modal.Body>
          <Row className='justify-content-md-center'>
            <Col style={{ fontSize: 20 }}>
              {t('pricing.pricing')}: <b>{t('pricing.' + pricingName + '.name')}</b>
            </Col>
          </Row>
          <Row className='mb-1 justify-content-md-center'>
            <Col style={{ marginTop: 20 }}>{t('billing.subscribeFree')}</Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col>
              <Button variant='success' onClick={activate}>
                <Gift /> {t('billing.activateFree')}
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    );
  }
);

export default SubscribeFreeDialog;
