/* eslint-disable no-nested-ternary */
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { ExclamationTriangle, FileEarmarkText, Plus, Tree } from 'react-bootstrap-icons';
import {
  Alert,
  Button,
  ButtonGroup,
  Col,
  Container,
  Row,
  Spinner,
  ToggleButton,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ROUTES } from 'components/App/App';
import moment from 'moment';
import { ProjectTypes, TProject, TProjectLARP } from 'stores/ProjectsStore';
import ProjectCard from 'components/Heplers/ProjectCard';
import { UserStatus } from 'stores/SessionStore';
import { useStores } from '../../services/useStore';

type TProjectCalendar = {
  date: string;
  projects: TProject[];
  isOld: boolean;
};

const ProjectList: React.FC = observer(() => {
  const { t } = useTranslation();
  const { sessionStore, projectStore, api } = useStores();
  const [inited, setInit] = useState(false);
  const [whoOld, setShowOld] = useState(false);
  const [isAllGames, setIsAllGames] = useState(true);
  const [list, setList] = useState<TProjectCalendar[]>([]);
  const [myProjects, setMyProjects] = useState<TProject[]>([]);
  let { data } = projectStore;
  if (!data) data = [];
  const mF = (d: moment.Moment) => moment(d.format('YYYY-MM') + '-01', 'YYYY-MM-DD');
  const calculateList = () => {
    let minDate: moment.Moment = moment();
    let maxDate: moment.Moment = moment();
    data.forEach((p: TProject) => {
      if (p.project_end) {
        const endDate = moment(p.project_start);
        if (endDate < minDate) minDate = endDate;
        if (endDate > maxDate) maxDate = endDate;
      }
    });
    minDate = mF(minDate);
    maxDate = mF(maxDate);
    let diff = maxDate.diff(minDate, 'months');
    if (!diff && data.length) diff = 1;
    if (diff) {
      const totallList: TProjectCalendar[] = [];
      for (let i = 0; i < diff; i++) {
        if (i) minDate.add(1, 'M');
        const lineList: TProjectCalendar = {
          date: minDate.format('YYYY-MM-DD'),
          projects: [],
          isOld: false,
        };
        lineList.projects = data.filter((p: TProject) => {
          //if (!p.project_open) return false;
          if (mF(moment(p.project_start)).format('YYYY-MM-DD') === lineList.date) {
            return true;
          }
          return false;
        });
        lineList.isOld = moment(lineList.date, 'YYYY-MM-DD').add(1, 'M') < moment();
        if (lineList.projects.length) {
          totallList.push(lineList);
        }
      }
      setList(totallList);
    }
    const myProjectsList: TProject[] = [];
    projectStore.data.forEach((project: TProject) => {
      if (project.status === UserStatus.Owner || project.status === UserStatus.Manager) {
        myProjectsList.push(project);
      }
    });
    setMyProjects(myProjectsList);
  };
  useEffect(() => {
    if (!projectStore?.data?.length) {
      api.getProjects().then(() => {
        setInit(true);
        calculateList();
      });
    } else {
      setInit(true);
      calculateList();
    }
  }, []);
  if (!inited) {
    return (
      <div style={{ display: 'flex', height: '100%' }}>
        <div style={{ margin: 'auto', display: 'flex' }}>
          <Spinner animation='border' role='status' />
          <span className='spiner-text'>{t('loading')}</span>
        </div>
      </div>
    );
  }
  const adminActions = (
    <Col className='rightText' sm={6}>
      <Link to={ROUTES.projectNew}>
        <Button variant='primary' className='mb-3' size='sm'>
          <div style={{ display: 'inline-block', position: 'relative', top: -1 }}>
            <Plus fontSize={16} />
          </div>
          {'  '}
          {t('projects.newProject')}
        </Button>
      </Link>
    </Col>
  );
  const projectName = (pr: TProject) => (
    <>
      {pr.project_type === ProjectTypes.LORE ? (
        <span style={{ marginRight: 10 }}>
          <FileEarmarkText />
        </span>
      ) : (
        ''
      )}
      {pr.project_type === ProjectTypes.LARP ? (
        <span style={{ marginRight: 10 }}>
          <Tree />
        </span>
      ) : (
        ''
      )}
      {pr.project_name}
    </>
  );

  const projectEditUrl = (pr: TProject) => {
    if (!sessionStore.role()) return '';
    if (pr.project_type === ProjectTypes.LORE) {
      if (pr.user_id === sessionStore.session.user_id) return 'projectEditLORE';
      return '';
    }

    if (api.isManager(pr.project_id, true)) return 'projectEditLARP';
    return '';
  };

  const getStyle = (item: TProject) => {
    return api.projectIsActive(item.project_id) ? {} : { backgroundColor: '#f3abab' };
  };
  const calculateFooter = (item: TProject) => {
    if (api.projectIsActive(item.project_id)) return <></>;
    return (
      <div
        style={{
          padding: '0 14px 21px',
          display: 'flex',
          justifyContent: 'flex-start',
          width: '100%',
          color: 'red',
        }}
      >
        <ExclamationTriangle fontSize={30} />
      </div>
    );
  };
  return (
    <Container fluid className='verticalPadding greenForm'>
      <Row className='justify-content-md-center'>
        <Col lg={10}>
          <Row className='mb-3 mt-3'>
            <Col sm={6}>
              <h1>{t('projects.mainTitle')}</h1>
            </Col>
            {sessionStore.session.user_id && !api.isBot() ? adminActions : ''}
          </Row>
          <Row className='mb-3'>
            {myProjects.length && sessionStore.session.user_id ? (
              <Col lg={2}>
                <ButtonGroup>
                  <ToggleButton
                    key='old'
                    id='game-all'
                    type='radio'
                    variant='outline-primary'
                    name='game'
                    value={1}
                    checked={isAllGames}
                    onChange={(e) => setIsAllGames(true)}
                  >
                    {t('global.all')}
                  </ToggleButton>
                  <ToggleButton
                    key='new'
                    id='game-my'
                    type='radio'
                    variant='outline-primary'
                    name='game'
                    value={1}
                    checked={!isAllGames}
                    onChange={(e) => setIsAllGames(false)}
                  >
                    {t('global.my')}
                  </ToggleButton>
                </ButtonGroup>
              </Col>
            ) : (
              ''
            )}
            {isAllGames || !sessionStore.session.user_id ? (
              <Col>
                <ButtonGroup>
                  <ToggleButton
                    key='old'
                    id='radio-old'
                    type='radio'
                    variant='outline-secondary'
                    name='radio'
                    value={1}
                    checked={whoOld}
                    onChange={(e) => setShowOld(true)}
                  >
                    {t('project.ended')}
                  </ToggleButton>
                  <ToggleButton
                    key='new'
                    id='radio-new'
                    type='radio'
                    variant='outline-success'
                    name='radio'
                    value={1}
                    checked={!whoOld}
                    onChange={(e) => setShowOld(false)}
                  >
                    {t('project.planed')}
                  </ToggleButton>
                </ButtonGroup>
              </Col>
            ) : (
              ''
            )}
          </Row>
          <Row>
            <Container fluid>
              {isAllGames || !sessionStore.session.user_id
                ? list
                    .filter((i: TProjectCalendar) => i.isOld === whoOld)
                    .map((i: TProjectCalendar) => (
                      <div key={i.date} className='mb-5'>
                        <Row>
                          <Col className='project-list-calendar'>
                            <Alert variant={i.isOld ? 'dark' : 'success'}>
                              {moment(i.date, 'YYYY-MM-DD').format('YYYY MMMM')}
                            </Alert>
                          </Col>
                        </Row>
                        {i.projects.map((project) => (
                          <Row className='mb-3' key={project.project_id}>
                            <Col>
                              <ProjectCard project={project} />
                            </Col>
                          </Row>
                        ))}
                      </div>
                    ))
                : myProjects.map((i: TProject) => (
                    <Row className='mb-3' key={i.project_id}>
                      <Col>
                        <ProjectCard project={i} />
                      </Col>
                    </Row>
                  ))}
            </Container>
          </Row>
        </Col>
      </Row>
    </Container>
  );
});
export default ProjectList;
