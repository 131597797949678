/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useEffect, useRef, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { useStores } from 'services/useStore';
import { Clock, Pencil, Person, Terminal } from 'react-bootstrap-icons';
import { TProject } from 'stores/ProjectsStore';
import { ROUTES } from 'components/App/App';
import MarkdownText from './MarkdownText';

export type TProjectCard = {
  project: TProject;
};

const ProjectCard: React.FC<TProjectCard> = ({ project }) => {
  const { api, projectStore } = useStores();
  const ref = useRef<HTMLDivElement>(null);
  const [projectName, setProjectName] = useState('');
  const [descripionHeight, setDescripionHeight] = useState('auto');
  const resize = () => {
    if (ref.current && ref.current.clientHeight > 300) {
      setDescripionHeight('250px');
    } else setDescripionHeight('auto');
  };
  const imageName = () => {
    if (project.img_id) return project.img_id + '.' + project.img_mime;
    if (project.project_id) {
      const projectData: TProject = projectStore.get(project.project_id);
      if (projectData.img_id) return projectData.img_id + '.' + projectData.img_mime;
    }
    return '0.png';
  };

  useEffect(() => {
    if (project.project_id) {
      if (project.project_name) setProjectName(project.project_name);
      else {
        const projectInfo: TProject = projectStore.get(project.project_id);
        if (projectInfo) setProjectName(projectInfo.project_name);
      }
    }
    setTimeout(resize, 100);
    const resizeObserver = new ResizeObserver(resize);
    if (ref.current) resizeObserver.observe(ref.current);
    return () => {
      if (ref.current) resizeObserver.unobserve(ref.current);
    };
  }, []);

  const currentId = project.project_id;
  if (!project.project_open && !api.isManager(currentId, false)) return <></>;

  const handler = (event: React.MouseEvent, clickId: number) => {
    event.preventDefault();
    event.stopPropagation();
    api.showDialog('project', currentId);
    return false;
  };

  const goToLink = async () => {
    await api.getSelectProject(project.project_id);
    window.location.href = ROUTES.projectEditLARP + '/' + project.project_id;
  };
  const goToProject = async () => {
    await api.getSelectProject(project.project_id);
    window.location.href = ROUTES.projects + '/' + project.project_id;
  };

  return (
    <Card
      className={'project-card' + (!project.project_id ? ' LT-project' : '')}
      ref={ref}
      onClick={(event) => handler(event, currentId)}
    >
      <div
        role='button'
        className='image'
        style={{
          backgroundImage: `url(${api.settings.url.server}img/${imageName()})`,
        }}
        onClick={goToProject}
      />
      <div className='project-data'>
        <div className='project-header'>
          <div className='name' role='button' onClick={goToProject}>
            {project.project_id ? projectName : 'LARP tools'}
          </div>

          {project.project_mg ? (
            <div role='link' style={{ display: 'flex', alignItems: 'center', fontSize: '0.8rem' }}>
              <b>{project.project_mg}</b>
            </div>
          ) : (
            ''
          )}
          {api.isManager(project.project_id, true) ? (
            <Button variant='primary' size='sm' onClick={() => goToLink()}>
              <Pencil />
            </Button>
          ) : (
            ''
          )}
        </div>
        <div
          className='description'
          style={{ height: descripionHeight }}
          role='button'
          onClick={goToProject}
        >
          <MarkdownText text={project.project_title} />
        </div>
        {descripionHeight !== 'auto' ? <div className='over' /> : ''}
        <div className='time'>
          <Clock style={{ position: 'relative', top: -3 }} />{' '}
          {api.timeFormat2(project.project_start)}
        </div>
      </div>
    </Card>
  );
};
export default ProjectCard;
