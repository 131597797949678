import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert, Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useStores } from 'services/useStore';
import { useTranslation } from 'react-i18next';
import { TFacebookAutResponse, TGoogleAutResponse, TSuccessResponse } from 'services/Api';
import { observer } from 'mobx-react';
import { TBlackList, TProfile, TUserRole, TUserSettings, UserStatus } from 'stores/SessionStore';
import { ROUTES } from 'components/App/App';
import { Trash } from 'react-bootstrap-icons';
import GoogleLoginButton from 'components/Buttons/GoogleLoginButton';
import FacebookLoginButton from 'components/Buttons/FacebookLoginButton';
import TelegramCheckButton from 'components/Heplers/TelegramCheckButton';
import UserFind from 'components/Heplers/UserFind';
import UserShow from 'components/Heplers/UserShow';
import FormElement from 'components/Heplers/FormElement';
import ImageList from 'components/Heplers/ImageList';
import InfoButton from 'components/Heplers/InfoButton';

const ProfileEdit: React.FC = observer(() => {
  const { api, sessionStore, projectStore } = useStores();
  const userId: number = Number(useParams().params) || +sessionStore.session.user_id;
  const { t } = useTranslation();
  const history = useNavigate();
  //api.noLogin();
  const { session } = sessionStore;
  const { firstRun } = window.localStorage;
  const [validated, setValidated] = useState(false);
  const [email, setEmail] = useState('');
  const [emailInvalid, setEmailInvalid] = useState('');
  const [password, setPassword] = useState('');
  const [repassword, setRePassword] = useState('');
  const [name, setName] = useState('');
  const [fio, setFio] = useState('');
  const [tel, setTel] = useState('');
  const [link, setLink] = useState('');
  const [contact, setContact] = useState('');
  const [deny, setDeny] = useState('');
  const [blacklist, setBlacklist] = useState('');
  const [tgCode, setTgCode] = useState('');
  const [tgId, setTgId] = useState(0);
  const [medinfo, setMedinfo] = useState('');
  const [successText, setSuccessText] = useState('');
  const [roles, setRoles] = useState<TUserRole[]>();
  const [bl, setBL] = useState<TBlackList[]>();
  const [wl, setWL] = useState<TBlackList[]>();
  const [imgList, setImgList] = useState([] as string[]);
  const [settings, setSettings] = useState<TUserSettings>();
  const [openAiKey, setOpenAiKey] = useState<string>();
  const [useAI, setUseAI] = useState(false);
  const [useAIOwerProject, setUseAIOwerProject] = useState(false);
  const isAdmin = session.user_status === UserStatus.Admin;

  const update = () => {
    api
      .getProfileData(userId)
      .then((data) => {
        if (data?.profile) {
          setBlacklist(data.profile.user_blacklist);
          setContact(data.profile.user_contact);
          setDeny(data.profile.user_deny);
          setEmail(data.profile.user_email);
          setFio(data.profile.user_fio);
          setLink(data.profile.user_link);
          setMedinfo(data.profile.user_medinfo);
          setName(data.profile.user_name);
          setTel(data.profile.user_tel);
          setRoles(data.profile.user_role);
          setTgCode(data.profile.telegram_check || '');
          setTgId(data.profile.telegram_id || 0);
          if (data.profile.img_id) setImgList([`${data.profile.img_id}.${data.profile.img_mime}`]);
          else setImgList([]);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          setBL(data.blacklist?.filter((i: TBlackList) => i.type === 'blacklist'));
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          setWL(data.blacklist?.filter((i: TBlackList) => i.type === 'whitelist'));
          const settingsData: TUserSettings =
            typeof data.profile.user_settings === 'string'
              ? JSON.parse(data.profile.user_settings.replaceAll("'", '"') || '{}')
              : data.profile.user_settings || {};
          setSettings(settingsData);
          setUseAI(settingsData.useAI || false);
          setUseAIOwerProject(settingsData.useAIOwerProject || false);
          setOpenAiKey(settingsData.openAiKey || '');
        }
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((e: any) => {
        console.warn(e);
      });
  };

  const updateImages = useCallback(() => {
    api.getProfileData(userId).then((data) => {
      if (data?.profile) {
        if (data.profile.img_id) setImgList([`${data.profile.img_id}.${data.profile.img_mime}`]);
        else setImgList([]);
      }
    });
  }, []);

  if (userId || !isAdmin) {
    useEffect(() => {
      update();
      setTimeout(() => {
        delete window.localStorage.firstRun;
      }, 500);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  }

  const checkRepass = (): boolean => {
    if (password !== '') {
      if (password === repassword) {
        //setReValid(true);
        return true;
      }
      return false;
    }
    return true;
  };

  useEffect(() => {
    setSettings({
      ...settings,
      useAI,
      openAiKey,
      useAIOwerProject,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useAIOwerProject, useAI, openAiKey]);

  const clear = (str: string) => (str ? str.replaceAll(';', '').replaceAll('"', "'") : ' ');

  const handleProfile = (event: React.FormEvent) => {
    const form = event.currentTarget as HTMLFormElement;
    if (form.checkValidity()) {
      if (checkRepass()) {
        setValidated(true);
        const data: TProfile = {
          user_blacklist: clear(blacklist),
          user_contact: clear(contact),
          user_deny: clear(deny),
          user_email: clear(email),
          user_fio: clear(fio),
          user_link: clear(link),
          user_medinfo: clear(medinfo),
          user_name: clear(name),
          user_tel: clear(tel),
          user_settings: JSON.stringify(settings),
        };
        if (password) data.user_pass = password;
        api.removeCacheFor(api.settings.url.profile + '/' + userId);
        api.updateProfile(data, userId).then((res: TSuccessResponse) => {
          const { errors } = res;
          if (errors) {
            if (errors.user_email) setEmailInvalid(errors.user_email);
          } else {
            console.log('OK');
            setSuccessText('profileForm.editSuccess');
            setTimeout(() => {
              setSuccessText('');
              if (firstRun) {
                history(ROUTES.home);
              }
            }, 5000);
          }
        });
        event.preventDefault();
        event.stopPropagation();
      }
    } else {
      event.preventDefault();
      event.stopPropagation();
    }

    return false;
  };

  const inputSize = api.isBot() ? 'sm' : 'lg';
  const successAlert = successText ? <Alert variant='success'>{t(successText)}</Alert> : '';
  const extForm = !firstRun ? (
    <Row className='justify-content-md-center'>
      <Col xs lg={10}>
        <Row>
          <Col xs lg={12}>
            <h2>{t('profileForm.maneProfile')}</h2>
            <FormElement controlId='email' label={t('loginForm.email')} className='mb-3'>
              <Form.Control
                type='email'
                required
                size={inputSize}
                value={email || ''}
                onChange={(e) => setEmail(e.target.value)}
                placeholder={t('loginForm.email')}
                isInvalid={!!emailInvalid}
              />
              {emailInvalid ? (
                <Form.Control.Feedback type='invalid'>
                  {t('registerForm.' + emailInvalid)}
                </Form.Control.Feedback>
              ) : (
                <Form.Text muted>{t('registerForm.emailDesc')}</Form.Text>
              )}
            </FormElement>
          </Col>
        </Row>
        <Row>
          <Col xs lg={12}>
            <FormElement controlId='password' label={t('loginForm.password')} className='mb-3'>
              <Form.Control
                type='password'
                size={inputSize}
                value={password || ''}
                onChange={(e) => setPassword(e.target.value)}
                placeholder={t('loginForm.password')}
              />
              <Form.Text muted>{t('registerForm.passwordDesc')}</Form.Text>
            </FormElement>
          </Col>
        </Row>
        <Row>
          <Col xs lg={12}>
            <FormElement
              controlId='repassword'
              label={t('registerForm.repassword')}
              className='mb-3'
            >
              <Form.Control
                type='password'
                size={inputSize}
                value={repassword || ''}
                onChange={(e) => setRePassword(e.target.value)}
                placeholder={t('registerForm.repassword')}
                isInvalid={!checkRepass()}
              />
              <Form.Text muted>{t('registerForm.repasswordDesc')}</Form.Text>
            </FormElement>
          </Col>
        </Row>
        <Row>
          <Col xs lg={12}>
            <FormElement controlId='name' label={t('registerForm.name')} className='mb-3'>
              <Form.Control
                type='text'
                required
                size={inputSize}
                value={name || ''}
                onChange={(e) => setName(e.target.value)}
                placeholder={t('registerForm.name')}
              />
              <Form.Text muted>{t('registerForm.nameDesc')}</Form.Text>
            </FormElement>
          </Col>
        </Row>
      </Col>
    </Row>
  ) : (
    <Alert variant='success'>{t('profileForm.firstEdit')}</Alert>
  );

  const WhiteList = (
    <Row className='justify-content-md-center' style={{ marginBottom: 24 }}>
      <Col xs lg={12}>
        <Row className='justify-content-md-center'>
          <Col>
            <h6>{t('profileForm.whitelist')}</h6>
          </Col>
        </Row>
        <Row className='justify-content-md-center'>
          <Col lg={12} className='one-line mb-3'>
            <div>
              <UserFind
                exeptions={[sessionStore.session.user_id]}
                action={(id: number) => {
                  api
                    .updateBL('add', 'whitelist', id)
                    .then((result) => update())
                    .catch((e) => {});
                }}
              />
            </div>
          </Col>
        </Row>
        {wl?.length ? (
          wl.map((user: TBlackList) => (
            <Row key={user.user_id} className='justify-content-md-center mb-1 profile-list'>
              <Col>
                <UserShow userId={user.user_id} allUsers />
              </Col>
              {user.user_id !== sessionStore.session.user_id ? (
                <Col xs={2}>
                  <Button
                    variant='danger'
                    size='sm'
                    style={{ fontSize: api.isBot() ? 18 : 25 }}
                    onClick={() => {
                      if (user.user_id)
                        api
                          .updateBL('delete', 'whitelist', user.user_id, t('global.deletePrompt'))
                          .then((result) => update());
                    }}
                  >
                    <Trash fontSize={api.isBot() ? 18 : 25} />
                  </Button>
                </Col>
              ) : (
                ''
              )}
            </Row>
          ))
        ) : (
          <Row className='mb-5 justify-content-md-center'>
            <Col xs lg={12} className='text-center'>
              {t('profileForm.blacklistEmpty')}
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );

  const BlackList = (
    <Row className='justify-content-md-center' style={{ marginBottom: 24 }}>
      <Col xs lg={12}>
        <Row className='justify-content-md-center'>
          <Col>
            <h6>{t('profileForm.blacklist')}</h6>
          </Col>
        </Row>
        <Row className='justify-content-md-center'>
          <Col lg={12} className='one-line mb-3'>
            <div>
              <UserFind
                exeptions={[sessionStore.session.user_id]}
                action={(id: number) => {
                  api
                    .updateBL('add', 'blacklist', id)
                    .then((result) => update())
                    .catch((e) => {});
                }}
              />
            </div>
          </Col>
        </Row>
        {bl?.length ? (
          bl.map((user: TBlackList) => (
            <Row key={user.user_id} className='justify-content-md-center mb-1 profile-list'>
              <Col xs={3}>
                <UserShow userId={user.user_id} allUsers />
              </Col>
              {user.user_id !== sessionStore.session.user_id ? (
                <Col xs={2}>
                  <Button
                    variant='danger'
                    size='sm'
                    style={{ fontSize: api.isBot() ? 18 : 25 }}
                    onClick={() => {
                      if (user.user_id)
                        api
                          .updateBL('delete', 'blacklist', user.user_id, t('global.deletePrompt'))
                          .then((result) => update());
                    }}
                  >
                    <Trash fontSize={api.isBot() ? 18 : 25} />
                  </Button>
                </Col>
              ) : (
                ''
              )}
            </Row>
          ))
        ) : (
          <Row className='mb-5 justify-content-md-center'>
            <Col xs lg={12} className='text-center'>
              {t('profileForm.blacklistEmpty')}
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );

  const roleBlock =
    isAdmin && roles?.length ? (
      <Row className='justify-content-md-center' style={{ marginTop: 24 }}>
        <Col xs lg={10}>
          <Row className='justify-content-md-center'>
            <Col>
              <h3>{t('profileForm.access')}</h3>
            </Col>
          </Row>
          {roles?.map((role: TUserRole) => (
            <Row
              key={role.project_id}
              className='justify-content-md-center'
              style={{
                border: '1px solid #00000022',
                borderRadius: 3,
                padding: 14,
                marginTop: 14,
              }}
            >
              <Col>
                <b>{projectStore.get(role.project_id).project_name}</b>
              </Col>
              <Col>{role.status}</Col>
              <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  variant='danger'
                  size='sm'
                  onClick={() => {
                    if (userId)
                      api
                        .updateRole('delete', userId, role.project_id, '', t('global.deletePrompt'))
                        .then((result) => {
                          update();
                        });
                  }}
                >
                  <Trash />
                </Button>
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
    ) : (
      ''
    );

  const connectGoogle = async (data: TGoogleAutResponse) => {
    if (session.googleId) return;
    await api.connectGoogleLogin(data);
  };
  const connectFB = async (data: TFacebookAutResponse) => {
    if (session.fbId) return;
    await api.connectFacebookLogin(data);
  };
  const socialNetworks = (
    <Row className='justify-content-md-center' style={{ marginTop: 14, marginBottom: 14 }}>
      <Col xs lg={10}>
        <Row className='justify-content-md-center'>
          <Col>
            <h3>{t('profileForm.socialNetworks')}</h3>
          </Col>
        </Row>
        {api.settings.serverSettings.googleLogin ? (
          <Row>
            <Col>
              <GoogleLoginButton
                clientId={api.settings.serverSettings.googleClientId}
                callback={connectGoogle}
                text={
                  session.googleId ? 'profileForm.googleConnected' : 'profileForm.googleConnect'
                }
                active={session.googleId}
              />
              {session.googleId ? (
                <Button
                  variant='danger'
                  style={{ marginLeft: 5 }}
                  onClick={() => api.disconnectSM('google')}
                >
                  <Trash />
                </Button>
              ) : (
                ''
              )}
            </Col>
          </Row>
        ) : (
          ''
        )}
        {api.settings.serverSettings.fbLogin ? (
          <Row>
            <Col>
              <FacebookLoginButton
                clientId={api.settings.serverSettings.facebookClientId}
                callback={connectFB}
                text={session.fbId ? 'profileForm.fbConnected' : 'profileForm.fbConnect'}
                active={session.fbId}
              />
              {session.fbId ? (
                <Button
                  variant='danger'
                  style={{ marginLeft: 5 }}
                  onClick={() => api.disconnectSM('facebook')}
                >
                  <Trash />
                </Button>
              ) : (
                ''
              )}
            </Col>
          </Row>
        ) : (
          ''
        )}
        {api.isBot() ? (
          ''
        ) : (
          <Row>
            <Col>
              <TelegramCheckButton code={tgId ? '' : tgCode} />
              {tgId ? (
                <Button
                  variant='danger'
                  style={{ marginLeft: 5 }}
                  onClick={() => api.disconnectSM('telegram')}
                >
                  <Trash />
                </Button>
              ) : (
                ''
              )}
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
  return (
    <Container fluid className='verticalPadding greenForm mt-3' style={{ marginBottom: 20 }}>
      <Form onSubmit={handleProfile} action='#' validated={validated}>
        {extForm}
        {sessionStore.session.user_id ? socialNetworks : ''}
        <Row className='justify-content-md-center'>
          <Col xs lg={10}>
            {sessionStore.session.user_id ? (
              <>
                <h2>{t('profileForm.extendedProfile')}</h2>
                <FormElement controlId='fio' label={t('profileForm.fio')} className='mb-3'>
                  <Form.Control
                    type='text'
                    required
                    size={inputSize}
                    value={fio || ''}
                    onChange={(e) => setFio(e.target.value)}
                    placeholder={t('profileForm.fio')}
                  />
                  <Form.Text muted>{t('profileForm.fioDesc')}</Form.Text>
                </FormElement>
                <FormElement controlId='tel' label={t('profileForm.tel')} className='mb-3'>
                  <Form.Control
                    type='text'
                    required
                    size={inputSize}
                    value={tel || ''}
                    onChange={(e) => setTel(e.target.value)}
                    placeholder={t('profileForm.tel')}
                  />
                  <Form.Text muted>{t('profileForm.telDesc')}</Form.Text>
                </FormElement>
                <FormElement controlId='link' label={t('profileForm.link')} className='mb-3'>
                  <Form.Control
                    type='text'
                    size={inputSize}
                    value={link || ''}
                    onChange={(e) => setLink(e.target.value)}
                    placeholder={t('profileForm.link')}
                  />
                  <Form.Text muted>{t('profileForm.linkDesc')}</Form.Text>
                </FormElement>
                <FormElement controlId='contact' label={t('profileForm.contact')} className='mb-3'>
                  <Form.Control
                    type='text'
                    size={inputSize}
                    value={contact || ''}
                    onChange={(e) => setContact(e.target.value)}
                    placeholder={t('profileForm.contact')}
                  />
                  <Form.Text muted>{t('profileForm.contactDesc')}</Form.Text>
                </FormElement>
                <FormElement
                  controlId='deny'
                  label={deny ? '' : t('profileForm.deny')}
                  className='mb-3'
                >
                  <Form.Control
                    as='textarea'
                    rows={5}
                    size={inputSize}
                    value={deny || ''}
                    onChange={(e) => setDeny(e.target.value)}
                    placeholder={t('profileForm.deny')}
                    style={{ height: '100px' }}
                  />
                  <Form.Text muted>{t('profileForm.denyDesc')}</Form.Text>
                </FormElement>
                <FormElement
                  controlId='medinfo'
                  label={medinfo ? '' : t('profileForm.medinfo')}
                  className='mb-3'
                >
                  <Form.Control
                    as='textarea'
                    rows={5}
                    size={inputSize}
                    value={medinfo || ''}
                    onChange={(e) => setMedinfo(e.target.value)}
                    placeholder={t('profileForm.medinfo')}
                    style={{ height: '100px' }}
                  />
                  <Form.Text muted>{t('profileForm.medinfoDesc')}</Form.Text>
                </FormElement>
                <Row className='justify-content-md-center'>
                  <Col xs lg={12} className='mb-3'>
                    <Row className='justify-content-md-center'>
                      <Col xs lg={12}>
                        <h2>{t('projectForm.useAI')}</h2>
                      </Col>
                    </Row>
                    <Row
                      className='justify-content-md-center'
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <Col xs lg={12}>
                        <Form.Check
                          type='switch'
                          key='useAI'
                          name='type'
                          style={{ marginBottom: 20 }}
                          label={t('projectForm.openAI')}
                          checked={useAI || false}
                          onChange={(e) => setUseAI(e.target.checked)}
                        />
                      </Col>
                    </Row>
                    {useAI ? (
                      <Row>
                        <Col>
                          <Row>
                            <Col xs lg={10}>
                              <Form.Control
                                type='text'
                                required
                                size='lg'
                                value={openAiKey || ''}
                                onChange={(e) => setOpenAiKey(e.target.value)}
                                placeholder={t('projectForm.openAIKey')}
                              />
                            </Col>
                            <Col xs lg={1}>
                              <InfoButton text='openAi' />
                            </Col>
                          </Row>
                          <Row>
                            <Col xs lg={12}>
                              <Form.Check
                                type='switch'
                                key='useAI'
                                name='type'
                                style={{ marginBottom: 20 }}
                                label={t('profileForm.userOpenAIKeyOverProject')}
                                checked={useAIOwerProject || false}
                                onChange={(e) => setUseAIOwerProject(e.target.checked)}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    ) : (
                      ' '
                    )}
                  </Col>

                  <Col xs lg={6}>
                    {roleBlock}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h2>{t('profileForm.bwlist')}</h2>
                  </Col>
                </Row>
                <Row>
                  <Col style={{ flexGrow: 10 }}>{WhiteList}</Col>
                  <Col />
                  <Col style={{ flexGrow: 10 }}>{BlackList}</Col>
                </Row>
              </>
            ) : (
              ''
            )}
            {successAlert}
          </Col>
        </Row>
        <Row className='justify-content-md-center'>
          <Col xs lg={2} className=' mb-3'>
            <div className='d-grid gap-2'>
              <Button variant='primary' size={inputSize} type='submit'>
                {t('global.save')}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
      {roleBlock}
      <Row className='justify-content-md-center'>
        <Col xs lg={10}>
          {userId ? (
            <Form.Group controlId='avatar' className='mb-3'>
              <Form.Label>
                {t(imgList.length ? 'profileForm.avatarPresent' : 'profileForm.avatar')}
              </Form.Label>
              <ImageList
                id={userId}
                type='person'
                imgList={imgList}
                noAI
                updateImagesHandle={updateImages}
                uploadUrl={'uploadUserImage/' + userId}
                max={1}
              />
              {imgList.length ? '' : <Form.Text muted>{t('profileForm.avatarDesc')}</Form.Text>}
            </Form.Group>
          ) : (
            ''
          )}
        </Col>
      </Row>
    </Container>
  );
});

export default ProfileEdit;
