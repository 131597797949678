/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useStores } from 'services/useStore';
import PricingDialog from 'components/Dialogs/PricingDialog';
import NewsCard from 'components/Heplers/NewsCard';
// eslint-disable-next-line import/no-extraneous-dependencies
import ReactPlayer from 'react-player';
import { TNews } from 'stores/NewsStore';

const Home: React.FC = () => {
  const { t } = useTranslation();
  const { sessionStore, api, newsStore } = useStores();
  const [showPricing, setShowPricing] = useState(false);
  const [inited, setInit] = useState(false);
  useEffect(() => {
    api.getLastNews().then(() => {
      setInit(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!inited) {
    return <div>{t('loading')}</div>;
  }
  return (
    <>
      <Container style={{ paddingTop: 24 }}>
        <div className='shadow welcome-container' style={{ margin: 'auto' }}>
          <h1 style={{ fontSize: 30 }}>
            {t('global.hello')}
            {sessionStore.session.user_name ? ' ' + sessionStore.session.user_name : ''}.{' '}
            {t('global.hello1')}
          </h1>

          <ReactPlayer
            url='https://www.youtube.com/watch?v=fyPrJO43NCo'
            width='100%'
            height='560px'
          />
          <p
            style={{ paddingTop: 24 }}
            dangerouslySetInnerHTML={{ __html: t('home.welcome').replaceAll('\n', '<br/><br/>') }}
          />
        </div>

        <Row className='justify-content-md-center'>
          <Col xs lg={10}>
            <Container style={{ paddingTop: 24 }} className='cont'>
              <Row>
                <Col>
                  <Alert variant='success'>
                    <h2>{t('news.mainTitle')}</h2>
                  </Alert>
                  {newsStore.data.reverse().map((news: TNews) => (
                    <NewsCard key={news.news_id} news={news} />
                  ))}
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
      <PricingDialog onlyView show={showPricing} hideCallback={() => setShowPricing(false)} />
    </>
  );
};
export default Home;
//<img src={logo} style={{ height: 50, margin: '-20px 0 -12px 0' }} alt='' />
