import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'services/useStore';
import { Modal } from 'react-bootstrap';
import PersonInfo from 'components/Persons/PersonInfo';
import {
  ArrowLeftSquare,
  Bug,
  FileEarmarkCheck,
  FileEarmarkPerson,
  FileEarmarkText,
  GeoAlt,
  Newspaper,
  Pencil,
  People,
  Person,
} from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import LocationInfo from 'components/Locations/LocationInfo';
import StatuseInfo from 'components/Statuses/StatuseInfo';
import QuestInfo from 'components/Quests/QuestInfo';
import { TQuest } from 'stores/QuestsStore';
import StuffInfo from 'components/Stuffs/StuffInfo';
import TextInfo from 'components/Texts/TextInfo';
import UserInfo from 'components/Users/UserInfo';
import RequestInfo from 'components/Requests/RequestInfo';
import { Link } from 'react-router-dom';
import { ROUTES } from 'components/App/App';
import { UserStatus } from 'stores/SessionStore';
import TasksInfo from 'components/Tasks/TasksInfo';
import NewsInfo from 'components/News/NewsInfo';
import BotRequestInfo from 'components/Requests/BotRequestInfo';
import BotPersonInfo from 'components/Persons/BotPersonInfo';
import BotStatuseInfo from 'components/Statuses/BotStatuseInfo';
import BugInfo from 'components/App/BugInfo';

type TContentData = {
  content: JSX.Element;
  header: JSX.Element;
  canEdit: boolean;
};

const MainDialog: React.FC = observer(() => {
  const { t } = useTranslation();
  const { api, sessionStore, questStore } = useStores();
  const [content, setContent] = useState<TContentData>();
  const checkManager = (): boolean =>
    api.isManager(sessionStore.session.user_project) && api.projectIsActive();
  useEffect(() => {
    if (!sessionStore.appSettings.dialogId) {
      api.closeDialog();
      return;
    }
    const id = sessionStore.appSettings.dialogId;
    switch (sessionStore.appSettings.dialogType) {
      case 'person':
        setContent({
          content: api.isBot() ? (
            <BotPersonInfo personId={id} linkInDialog />
          ) : (
            <PersonInfo personId={id} linkInDialog />
          ),
          header: (
            <h4>
              <Person /> {t('requestForm.person')}
            </h4>
          ),
          canEdit: checkManager(),
        });
        break;
      case 'location':
        setContent({
          content: <LocationInfo locationId={id} linkInDialog />,
          header: (
            <h4>
              <GeoAlt /> {t('location.location')}
            </h4>
          ),
          canEdit: checkManager(),
        });
        break;
      case 'status':
        setContent({
          content: api.isBot() ? (
            <BotStatuseInfo statusId={id} linkInDialog />
          ) : (
            <StatuseInfo statusId={id} linkInDialog />
          ),
          header: (
            <h4>
              <People /> {t('status.status')}
            </h4>
          ),
          canEdit: checkManager(),
        });
        break;
      case 'news':
        setContent({
          content: <NewsInfo newsId={id} linkInDialog />,
          header: (
            <h4>
              <Newspaper /> {t('news.news')}
            </h4>
          ),
          canEdit: checkManager(),
        });
        break;
      case 'quest':
        {
          const questType =
            (questStore.get(id) as TQuest)?.quest_type === 'plot'
              ? 'questForm.types.plot'
              : 'questForm.types.quest';
          setContent({
            content: <QuestInfo questId={id} linkInDialog />,
            header: (
              <h4>
                <FileEarmarkCheck /> {t(questType)}
              </h4>
            ),
            canEdit: checkManager(),
          });
        }
        break;
      case 'stuff':
        setContent({
          content: <StuffInfo stuffId={id} linkInDialog />,
          header: (
            <h4>
              <FileEarmarkCheck /> {t('stuff.stuff')}
            </h4>
          ),
          canEdit: checkManager(),
        });
        break;
      case 'text':
        setContent({
          content: <TextInfo textId={id} linkInDialog imageHeight={api.isBot() ? 300 : 600} />,
          header: (
            <h4>
              <FileEarmarkText /> {t('text.text')}
            </h4>
          ),
          canEdit: checkManager(),
        });
        break;
      case 'info':
        setContent({
          content: <TextInfo textId={id} linkInDialog={false} />,
          header: (
            <h4>
              <FileEarmarkText /> {t('text.text')}
            </h4>
          ),
          canEdit: false,
        });
        break;
      case 'user':
        setContent({
          content: <UserInfo userId={id} linkInDialog />,
          header: (
            <h4>
              <Person /> {t('global.player')}
            </h4>
          ),
          canEdit: sessionStore.session.user_status === UserStatus.Admin,
        });
        break;
      case 'request':
        setContent({
          content: api.isBot() ? (
            <BotRequestInfo isWindow requestId={id} />
          ) : (
            <RequestInfo requestId={id} linkInDialog />
          ),
          header: (
            <h4>
              <FileEarmarkPerson /> {t('request.request')}
            </h4>
          ),
          canEdit: checkManager(),
        });
        break;
      case 'invite':
        setContent({
          content: api.isBot() ? (
            <BotRequestInfo isWindow requestId={id} />
          ) : (
            <RequestInfo requestId={id} linkInDialog />
          ),
          header: (
            <h4>
              <FileEarmarkPerson /> {t('request.invite2')}
            </h4>
          ),
          canEdit: checkManager(),
        });
        break;
      case 'task':
        setContent({
          content: <TasksInfo taskId={id} linkInDialog />,
          header: (
            <h4>
              <FileEarmarkPerson /> {t('task.mainTitle')}
            </h4>
          ),
          canEdit: checkManager(),
        });
        break;
      case 'bug':
        setContent({
          content: <BugInfo bugId={id} linkInDialog />,
          header: (
            <h4>
              <Bug /> {t('bug.bug')} #{id}
            </h4>
          ),
          canEdit: checkManager(),
        });
        break;
      default:
        console.log('Undefined window:', sessionStore.appSettings.dialogType);
        api.closeDialog();
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionStore.appSettings.dialogId, sessionStore.appSettings.dialogType]);
  return (
    <Modal
      show={!!sessionStore.appSettings.dialogId}
      size='lg'
      key='editModal'
      onHide={api.closeDialog}
    >
      <Modal.Header closeButton>
        {sessionStore.appSettings.dialogHistory.length > 1 ? (
          <ArrowLeftSquare
            style={{ marginRight: 14, fontSize: 20, cursor: 'pointer' }}
            onClick={api.historyDialog}
          />
        ) : (
          ''
        )}
        {content?.header}
        {!api.isBot() && content?.canEdit && sessionStore.appSettings.dialogType ? (
          <div style={{ position: 'absolute', right: 43 }}>
            <Link
              className='btn btn-success'
              to={
                ROUTES[(sessionStore.appSettings.dialogType + 'Edit') as keyof typeof ROUTES] +
                '/' +
                sessionStore.appSettings.dialogId
              }
              type='button'
              onClick={api.closeDialog}
            >
              <Pencil />
            </Link>
          </div>
        ) : (
          ''
        )}
      </Modal.Header>
      <Modal.Body>{content?.content}</Modal.Body>
    </Modal>
  );
});

export default MainDialog;
