import { makeAutoObservable } from 'mobx';
import Api from '../services/Api';
import GraphFilterStore from './GraphFilterStore';
import LinkStore from './LinkStore';
import LocationsStore from './LocationsStore';
import PersonsStore from './PersonsStore';
import GeneratedPersonsStore from './GeneratedPersonsStore';
import ProjectsStore from './ProjectsStore';
import QuestsStore from './QuestsStore';
import RelationsStore from './RelationsStore';
import RequestsStore from './RequestsStore';
import SessionStore from './SessionStore';
import StatusesStore from './StatusesStore';
import NewsStore from './NewsStore';
import StuffsStore from './StuffsStore';
import TasksStore from './TasksStore';
import TextFoldersStore from './TextFoldersStore';
import TextsStore from './TextsStore';
import UsersStore from './UsersStore';
import GeneratedLocationsStore from './GeneratedLocationsStore';
import GeneratedQuestsStore from './GeneratedQuestsStore';
import BillingsStore from './BillingsStore';
import StuffFoldersStore from './StuffFoldersStore';
import PrototypeLocationStore from './PrototypeLocationStore';
import PrototypeActionStore from './PrototypeActionStore';
import PrototypeGroupStore from './PrototypeGroupStore';
import PrototypePersonStore from './PrototypePersonStore';
import PrototypeLinkStore from './PrototypeLinkStore';
import BugsStore from './BugsStore';

export interface IAppStore {
  api: Api;
}

export class RootStore implements IAppStore {
  sessionStore = new SessionStore(this);
  api = new Api(this);
  projectStore = new ProjectsStore(this);
  locationStore = new LocationsStore(this);
  statusStore = new StatusesStore(this);
  newsStore = new NewsStore(this);
  personStore = new PersonsStore(this);
  userStore = new UsersStore(this);
  questStore = new QuestsStore(this);
  stuffStore = new StuffsStore(this);
  taskStore = new TasksStore(this);
  textStore = new TextsStore(this);
  textFolderStore = new TextFoldersStore(this);
  stuffFoldersStore = new StuffFoldersStore(this);
  requestStore = new RequestsStore(this);
  billingsStore = new BillingsStore(this);
  graphFilterStore = new GraphFilterStore(this);
  bugsStore = new BugsStore(this);

  questLinks = new LinkStore(this);
  statusLinks = new LinkStore(this);
  stuffLinks = new LinkStore(this);
  textLinks = new LinkStore(this);
  personLinks = new LinkStore(this);
  projectLinks = new LinkStore(this);
  locationLinks = new LinkStore(this);
  //requestLinks = new LinkStore(this); //?

  relationLinks = new RelationsStore(this);

  generatedPersonsStore = new GeneratedPersonsStore(this);
  generatedLocationsStore = new GeneratedLocationsStore(this);
  generatedQuestsStore = new GeneratedQuestsStore(this);

  prototypeLocationStore = new PrototypeLocationStore(this);
  prototypeActionStore = new PrototypeActionStore(this);
  prototypeGroupStore = new PrototypeGroupStore(this);
  prototypePersonStore = new PrototypePersonStore(this);
  prototypeLinkStore = new PrototypeLinkStore(this);

  constructor() {
    makeAutoObservable(this);
  }
}
