/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'services/useStore';
import { Button, Col, Container, Row, InputGroup, Form, Alert } from 'react-bootstrap';
import HomeBotInfoDialog from 'components/Dialogs/HomeBotInfoDialog';
import { useNavigate } from 'react-router';
import image from '../../assets/img/welcome-image2.png';
import codeImage from '../../assets/img/code.jpg';
import { ROUTES } from './App';

const BotHome: React.FC = () => {
  const history = useNavigate();
  const { t } = useTranslation();
  const [state, setState] = useState(' ');
  const [error, setError] = useState<string>();
  const [code, setCode] = useState<string>();
  const { sessionStore, projectStore, api } = useStores();
  const projectName = sessionStore.session?.user_project
    ? projectStore.get(sessionStore.session.user_project)?.project_name
    : '';
  const enterCode = async () => {
    if (!code) return;
    setError('');
    const { userId } = await api.registerTelegram(api.telegram.user.id, code);
    if (!userId) {
      setError('Не вірний код');
      setTimeout(() => setError(''), 3000);
    } else {
      window.location.reload();
    }
  };
  const register = async () => {
    history(ROUTES.registration);
    /*const { tg } = api.telegram;
    await api.toBot(api.telegram.user.id, '🦉 Не хвилюйтесь зараз ми швиденько вас зареєструємо.', {
      button: 'Зареєструватись',
      callback_data: 'registration',
    });
    tg.close();*/
  };
  useEffect(() => {
    if (!api.access) return;
    if (api.isLogin()) setState('main');
    else setState('noLogin');
    const title =
      projectName ||
      (sessionStore.session?.user_name
        ? t('global.hello') + ' ' + sessionStore.session.user_name
        : '');
    sessionStore.tgHeader(title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api.access]);
  return (
    <>
      <div style={{ backgroundImage: `url(${image})` }} className='welcome-image'>
        {state === 'main' ? (
          <div className='welcome-text'>
            <p
              dangerouslySetInnerHTML={{
                __html: t('welcome').replaceAll('\n', '<br/><br/>'),
              }}
            />
          </div>
        ) : (
          ''
        )}
        {state === 'noLogin' ? (
          <Container className='welcome-text' style={{ width: 'auto' }}>
            <Row className='justify-content-md-center'>
              <Col>
                <p>
                  {t('global.hello')} <b>{api.telegram.user.first_name}</b>
                </p>
                <p>
                  {t('global.doYouHaveAccount')}
                  <br />
                  <a href={api.settings.serverSettings.serverFE} target='_blank' rel='noreferrer'>
                    LARP Tools
                  </a>{' '}
                  ?
                </p>
              </Col>
            </Row>
            <Row className='justify-content-md-center' style={{ justifyContent: 'space-around' }}>
              <div style={{ display: 'flex', width: '70%', justifyContent: 'space-between' }}>
                <Button style={{ width: '40%' }} onClick={() => setState('enterCode')}>
                  {t('global.yes')}
                </Button>
                <Button style={{ width: '40%' }} onClick={register}>
                  {t('global.no')}
                </Button>
              </div>
            </Row>
          </Container>
        ) : (
          ''
        )}
        {state === 'enterCode' ? (
          <Container className='welcome-text' style={{ width: 'auto' }}>
            <Row className='justify-content-md-center'>
              <Col>
                <p>
                  {t('profileForm.findCode1')}{' '}
                  <a href={api.settings.serverSettings.serverFE} target='_blank' rel='noreferrer'>
                    LARP Tools
                  </a>{' '}
                  {t('profileForm.findCode2')}
                </p>
              </Col>
            </Row>
            <Row className='justify-content-md-center' style={{ justifyContent: 'space-around' }}>
              <img src={codeImage} alt='code' />
            </Row>
            <Row
              className='justify-content-md-center mt-3'
              style={{ justifyContent: 'space-around' }}
            >
              <InputGroup style={{ width: '80%' }}>
                <Form.Control
                  placeholder={t('profileForm.telegramCode')}
                  type='text'
                  onChange={(e) => setCode(e.target.value)}
                  value={code}
                />
                <Button onClick={enterCode}>{t('profileForm.findCode3')}</Button>
              </InputGroup>
            </Row>
            {error ? (
              <Row
                className='justify-content-md-center mt-3'
                style={{ justifyContent: 'space-around' }}
              >
                <Alert variant='danger'>
                  <b>{error}</b>
                </Alert>
              </Row>
            ) : (
              ''
            )}
          </Container>
        ) : (
          ''
        )}
      </div>
      <HomeBotInfoDialog />
    </>
  );
};
export default BotHome;
//<img src={logo} style={{ height: 50, margin: '-20px 0 -12px 0' }} alt='' />
