/* eslint-disable react/button-has-type */
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Container, FloatingLabel, Form, Modal, Row } from 'react-bootstrap';
import { useStores } from 'services/useStore';
import { useTranslation } from 'react-i18next';
import { TFacebookAutResponse, TSessionRequest } from 'services/Api';
import { observer } from 'mobx-react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { gapi } from 'gapi-script';
import { Envelope, Plus } from 'react-bootstrap-icons';
import RegisterForm from '../LoginRegistration/RegisterForm';
import ForgotForm from '../LoginRegistration/ForgotForm';
import GoogleLoginButton from '../Buttons/GoogleLoginButton';
import FacebookLoginButton from '../Buttons/FacebookLoginButton';

const LofinFormDialog: React.FC = observer(() => {
  const { t /*, i18n*/ } = useTranslation();
  const { api, sessionStore } = useStores();
  const [email, setEmail] = useState('');
  const [mode, setMode] = useState('login');
  const [emailInvalid, setEmailInvalid] = useState('');
  const [password, setPassword] = useState('');
  const [asEmail, setAsEmail] = useState(false);
  const hide = useCallback(() => {
    api.toggleLoginDialog(false);
  }, []);

  const onSetMode = useCallback((newMode: string) => {
    setMode(newMode);
  }, []);

  const handleLogin = (event: React.FormEvent) => {
    const form = event.currentTarget as HTMLFormElement;
    if (form.checkValidity()) {
      api.setLogin(email, password).then((res: TSessionRequest) => {
        const { errors } = res;
        if (errors) {
          if (errors.user_email) setEmailInvalid(errors.user_email);
        } else {
          hide();
          console.log('OK');
          window.location.reload();
        }
      });
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      event.stopPropagation();
    }

    return false;
  };
  /*const changeLang = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault();
    const newLang = event.target.value;
    i18n.changeLanguage(newLang);
  };*/

  const authstart = () => {
    gapi.client.init({
      clientId: api.settings.serverSettings.googleClientId,
      scope: '',
    });
  };

  const checkFB = async (data: TFacebookAutResponse) => {
    const loginData = await api.checkFacebookLogin(data);
    if (loginData.session) {
      window.location.reload();
    } else if (loginData.errors?.user_email === 'fbLoginError') {
      window.localStorage.fbId = data.id;
      setMode('register');
    }
  };

  useEffect(() => {
    gapi.load('client:auth2', authstart);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (mode === 'register') {
    return <RegisterForm setMode={onSetMode} hide={hide} />;
  }

  if (mode === 'forgot') {
    return <ForgotForm setMode={onSetMode} hide={hide} email={email} />;
  }

  return (
    <Modal
      show={!!sessionStore.appSettings.loginDialog}
      size='sm'
      key='editModal'
      centered
      onHide={hide}
    >
      <Form onSubmit={handleLogin}>
        <Modal.Header closeButton>{t('loginForm.header')}</Modal.Header>
        <Modal.Body>
          <Container>
            {/*<Row className='justify-content-md-center'>
              <Col>
                <Form.Select size='sm' value={i18n.language} onChange={changeLang}>
                  <option value='ua'>UA</option>
                  <option value='en'>EN</option>
                </Form.Select>
              </Col>
  </Row>*/}
            {api.settings.serverSettings.googleLogin ? (
              <Row className='justify-content-md-center'>
                <Col>
                  <GoogleLoginButton
                    clientId={api.settings.serverSettings.googleClientId}
                    callback={(data) => api.checkGoogleLogin(data)}
                  />
                </Col>
              </Row>
            ) : (
              ''
            )}
            {api.settings.serverSettings.fbLogin ? (
              <Row className='justify-content-md-center'>
                <Col>
                  <FacebookLoginButton
                    clientId={api.settings.serverSettings.facebookClientId}
                    callback={(data) => checkFB(data)}
                  />
                </Col>
              </Row>
            ) : (
              ''
            )}
            <Row className='justify-content-md-center'>
              <Col>
                <button
                  onClick={() => setAsEmail(!asEmail)}
                  type='button'
                  className='customLoginButton'
                >
                  <div>
                    <Envelope fontSize={20} color='#0d6efd' />
                  </div>{' '}
                  {t('loginForm.emailAddress')}
                </button>
              </Col>
            </Row>
            <Row className='justify-content-md-center mt-3'>
              <Col>
                <button
                  onClick={() => setMode('register')}
                  type='button'
                  className='customLoginButton registration'
                >
                  <div>
                    <Plus fontSize={20} color='#1f9a06' />
                  </div>{' '}
                  {t('registerForm.header')}
                </button>
              </Col>
            </Row>
          </Container>
          {asEmail ? (
            <>
              <hr />
              <Container fluid className='verticalPadding greenForm'>
                <Row>
                  <Col>
                    <FloatingLabel
                      controlId='floatingInput'
                      label={t('loginForm.email')}
                      className='mb-3'
                    >
                      <Form.Control
                        type='email'
                        size='sm'
                        autoComplete='username'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder={t('loginForm.email')}
                        isInvalid={!!emailInvalid}
                      />
                      {emailInvalid ? (
                        <Form.Control.Feedback type='invalid'>
                          {t('loginForm.' + emailInvalid)}
                        </Form.Control.Feedback>
                      ) : (
                        ''
                      )}
                    </FloatingLabel>
                    <FloatingLabel controlId='floatingPassword' label={t('loginForm.password')}>
                      <Form.Control
                        type='password'
                        autoComplete='current-password'
                        size='sm'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder={t('loginForm.password')}
                      />
                    </FloatingLabel>
                  </Col>
                </Row>
                <Row className='mb-3'>
                  <Col>
                    <Button
                      variant='link'
                      size='sm'
                      style={{ float: 'right', boxShadow: 'none' }}
                      onClick={() => setMode('forgot')}
                    >
                      {t('loginForm.forgot')}
                    </Button>
                  </Col>
                </Row>
                <Row className='justify-content-md-center'>
                  <Col xs lg={12}>
                    <Button variant='primary' size='sm' type='submit' style={{ width: '100%' }}>
                      {t('loginForm.signIn')}
                    </Button>
                  </Col>
                </Row>
              </Container>
            </>
          ) : (
            ''
          )}
        </Modal.Body>
      </Form>
    </Modal>
  );
});

export default LofinFormDialog;
